import React, { useState } from 'react';

import {
    ResizableHandle,
    ResizablePanel,
    ResizablePanelGroup,
} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import {UploadNewPhoto} from "@/pages/stories/upload-new-photo/upload-new-photo";
import NewStoryForm from "@/pages/stories/new-story/new-story-form";
import AllStoryPage from "@/pages/stories/all-stories/stories-page";
import StoriesMenu from "@/pages/stories/stories-menu";

// Основной компонент
const StoriesMainPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");

    // Функция для рендеринга выбранного компонента на главной странице
    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "all-stories":
                return <AllStoryPage />;
            case "new-story":
                return <NewStoryForm/>;
            case "new-photo":
                return <UploadNewPhoto />
            default:
                return <AllStoryPage />;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <StoriesMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default StoriesMainPage;
