"use client"

import {useForm} from "react-hook-form"

import {Button} from "@/components/ui/button"
import {Form, FormControl, FormDescription, FormField, FormItem, FormLabel,} from "@/components/ui/form"
import {Switch} from "@/components/ui/switch"
import {toast} from "sonner";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import React, {useEffect, useState} from "react";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import {useApi} from "@/pages/api-provider/api-context";

interface LockScreenMode {
    active: boolean;
}

async function getStatus(apiUrl: string): Promise<LockScreenMode> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/app-status`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

export function LockScreenSwitchForm() {
    const {apiUrl} = useApi();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    const form = useForm<LockScreenMode>({
        defaultValues:{
            active: false
        }
    })

    // Симуляция получения данных с сервера
    useEffect(() => {
        const fetchData = async () => {
            try {
                const lockScreenMode = await getStatus(apiUrl);
                form.setValue('active', lockScreenMode.active)
            } catch (err) {
                console.error(err);
                toast.warning("Oops!", {
                    description: `Something went wrong, check console! ${err}`,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);


    const handleSubmit = async () => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/app-status`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ active: form.getValues("active") }), // Отправляем значение банка
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Ошибка при отправке данных");
            }

            const isEnabled = form.getValues("active")
            toast.success("Lock screen",{
                description: !isEnabled ? "Disabled" : "Enabled",

            })

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, check the console! ${error}`,
            });
        }
    };

    const openSubmitDialog = () => {
        setOpen(true);
    };

    if (loading) {
        return <div>Getting data from server...</div>;
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(openSubmitDialog)} className="w-full space-y-6">
                <div>
                    <h3 className="mb-4 text-lg font-medium">Lock screen</h3>
                    <div className="space-y-4">
                        <FormField
                            control={form.control}
                            name="active"
                            render={({ field }) => (
                                <FormItem className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                    <div className="space-y-0.5">
                                        <FormLabel>Disable/Enable app</FormLabel>
                                        <FormDescription>
                                            <p>
                                                True - means app is active
                                            </p>
                                            <p>
                                                False - app is disabled and u can modify any data =)
                                            </p>
                                        </FormDescription>
                                    </div>
                                    <FormControl>
                                    <Switch
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                    </div>
                </div>
                <Button type="submit">Submit</Button>
                {/* Диалоговое окно подтверждения удаления */}
                <AlertDialog open={open} onOpenChange={setOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={handleSubmit}>Confirm</AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </form>
        </Form>
    )
}
