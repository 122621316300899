import React, {useEffect, useState} from 'react';
import {useApi} from '../../api-provider/api-context';
import {columns, Stories, StoriesTable} from "@/pages/stories/all-stories/stories-table";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

// Интерфейсы для работы с данными
interface Image {
    title: string;
    url: string;
}

interface ImagesResponse {
    images: Image[];
}

// Основной компонент
const AllStoryPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [data, setData] = useState<Stories | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибок
    const [open, setOpen] = useState(false);
    const [storiesIdToDelete, setStoriesIdToDelete] = useState<number | null>(null);

    const openDeleteDialog = (id: number) => {
        setStoriesIdToDelete(id);
        setOpen(true);
    };

    const handleDeleteStories = async () => {
        if (storiesIdToDelete === null) return; // Проверка на случай, если id не установлен
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/delete-story`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    story_id: storiesIdToDelete,
                }),
            });

            // Проверка статуса ответа
            if (!response.ok) {
                throw new Error(`Ошибка при запросе: ${response.status}`);
            }

            // Проверка результата удаления задачи
            if (response.ok) {
                toast.success("Success",{
                    description: `Story deleted!`,
                });
                refreshAllData(); // Обновляем данные после удаления
            } else {
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            }
        } catch (error) {
            console.error("Update data:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console!`,
            })
        } finally {
            setOpen(false); // Закрываем диалоговое окно
            setStoriesIdToDelete(null); // Сбрасываем id задачи
        }
    };




    // Функция для получения историй
    const getStories = async (): Promise<Stories> => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/get-all-stories`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error(`send data: ${response.status}`);
            }

            const resp = await response.json();
            return resp;

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
            throw error;
        }
    };

    const refreshAllData = async () => {
        try {
            const promo = await getStories();
            setData(promo);
        } catch (error) {
            console.error("Update data:", error);
            setError("Update data");
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    // useEffect для загрузки данных при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const stories = await getStories(); // Получаем истории
                setData(stories); // Сохраняем истории в состоянии
            } catch (err) {
                setError("update data");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);


    return (
        <div className="w-full h-full">
            <div className="text-lg text-muted-foreground">
                {data?.stories ? (
                    <div>
                    <StoriesTable columns={columns} data={data.stories} onDelete={openDeleteDialog} />
                        {/* Диалоговое окно подтверждения удаления */}
                        <AlertDialog open={open} onOpenChange={setOpen}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This action cannot be undone. This will permanently delete story.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={handleDeleteStories}>Delete</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>
                ) : (
                    <div>Stories is empty</div>
                )}
            </div>
        </div>
    );
};

export default AllStoryPage;
