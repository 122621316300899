import React, {useState} from 'react';

import {ResizableHandle, ResizablePanel, ResizablePanelGroup,} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import FortuneWheelMenu from "@/pages/fortune/fortune-wheel-menu";
import SpinConfigForm from "@/pages/fortune/wheel/settings/spin-config-form";
import AllGoodsPage from "@/pages/fortune/shop/goods/all-goods-page";


// Основной компонент
const FortuneWheelMainPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");

    // Функция для рендеринга выбранного компонента на главной странице
    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "fortune-settings":
                return <SpinConfigForm/>;
            case "new-goods":
                return <AllGoodsPage/>;
            default:
                return <div>main</div>;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <FortuneWheelMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default FortuneWheelMainPage;
