import React from 'react';
import {Button} from "@/components/ui/button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {ChevronLeft, ChevronRight} from "lucide-react";

interface PaginationControlsProps {
    onPageChange: (page: number) => void;
    onOffsetChange: (offset: number) => void;
    page: number;
    offset: number;
}

const PaginationControls: React.FC<PaginationControlsProps> = ({ onPageChange, onOffsetChange, page, offset }) => {
    // Обработчики для изменения страницы
    const handlePrevPage = () => {
        if (page > 1) {
            const newPage = page - 1;
            onPageChange(newPage); // Передаем новое значение страницы
        }
    };

    const handleNextPage = () => {
        const newPage = page + 1;
        onPageChange(newPage); // Передаем новое значение страницы
    };

    // Обработчик изменения offset
    const handleOffsetChange = (value: string) => {
        const newOffset = Number(value);
        onOffsetChange(newOffset); // Передаем новое значение offset
        onPageChange(1); // Передаем первую страницу
    };

    return (
        <div className="flex flex-row items-center justify-center">
            {/* Кастомный селект по центру */}
            <div className="mb-4 text-center">
                <Select
                    value={`${offset}`} // Привязываем текущее значение offset
                    onValueChange={handleOffsetChange} // Обрабатываем изменения
                >
                    <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder={`${offset}`}/> {/* Отображаем текущее значение offset */}
                    </SelectTrigger>
                    <SelectContent>
                        <SelectItem value="50">50</SelectItem>
                        <SelectItem value="100">100</SelectItem>
                        <SelectItem value="500">500</SelectItem>
                    </SelectContent>
                </Select>
            </div>

            {/* Кнопки справа под таблицей */}
            <div className="flex justify-end w-full mt-4 p-6 pr-0 pt-0">
                <Button
                    variant="outline"
                    onClick={handlePrevPage}
                    disabled={page === 1}
                    className="mr-2"
                >
                    <ChevronLeft/>
                </Button>
                <Button variant="outline">{page}</Button>
                <Button
                    variant="outline"
                    onClick={handleNextPage}
                >
                <ChevronRight />
                </Button>
            </div>
        </div>
    );
};

export default PaginationControls;
