import {columns as promoColumns, Promo, PromoTable} from "./promo-table"; // Импортируем колонки для первой таблицы
import {useApi} from "../../api-provider/api-context";
import React, {useEffect, useState} from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth"; // Импортируем PromoForm

// Функция для получения промо-данных
async function getPromoStat(apiUrl: string): Promise<Promo[]> {
    const response = await FetchWithAuth(`${apiUrl}/admin/get-promo-stat`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(`Ошибка при запросе: ${response.status}`);
    }

    const data = await response.json();
    return data;
}

const AllPromoPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [promoData, setPromoData] = useState<Promo[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [promoIdToDelete, setPromoIdToDelete] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const promo = await getPromoStat(apiUrl);
                setPromoData(promo);

            } catch (err) {
                setError("Ошибка при загрузке данных");
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    const handleDeletePromo = async () => {
        toast.info(`Удаление промо ${promoIdToDelete} как только Женя сделает мне ручку`)
    };

    // Функция для открытия диалогового окна
    const openDeleteDialog = (id: number) => {
        setPromoIdToDelete(id);
        setOpen(true);
    };


    return (
            <div className="text-lg">
            {promoData.length > 0 ? (
                <div>
                <PromoTable columns={promoColumns} data={promoData} onDelete={openDeleteDialog}/>
                    {/* Диалоговое окно подтверждения удаления */}
                    <AlertDialog open={open} onOpenChange={setOpen}>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    This action cannot be undone. This will permanently delete promo.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                                <AlertDialogAction onClick={handleDeletePromo}>Delete</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>
                </div>
            ) : (
                <div>no view data</div>
            )}
        </div>
    );
};

export default AllPromoPage;
