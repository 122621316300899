import React from "react";
import {ArrowRightLeft, LockIcon, PiggyBank, MessageSquarePlusIcon} from "lucide-react";
import {Command, CommandGroup, CommandItem, CommandList, CommandSeparator,} from "@/components/ui/command";
import {Card} from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function SettingsMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[450px] py-1">
                <CommandList>
                    <CommandSeparator/>
                    <CommandGroup heading="App">
                        <CommandItem onSelect={() => onSelectCommand("lock-screen")}>
                            <LockIcon size={20} className="mr-2"  />
                            <span>Lock Screen</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandGroup heading="Referrals">
                        <CommandItem onSelect={() => onSelectCommand("change-ref-link")}>
                            <ArrowRightLeft size={20} className="mr-2" />
                            <span>Change ref link</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandGroup heading="TON">
                        <CommandItem onSelect={() => onSelectCommand("setup-bank")}>
                            <PiggyBank size={20} className="mr-2" />
                            <span>Setup Bank</span>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </Card>
    );
}
