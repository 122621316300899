import React, {useState} from 'react';
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {useApi} from '../../api-provider/api-context';
import {Card, CardTitle} from "@/components/ui/card";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


// TODO: set timeout!
export function AddMoneyToAll() {
    const [amount, setMoney] = useState<string | ''>(''); // Хранение ID как число или пустая строка
    const { apiUrl } = useApi();


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/add-money-to-all-users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: amount },),
                credentials: 'include' // Отправляем userId в теле запроса
            });
            if (!response.ok) {
                throw new Error('Ошибка при отправке данных');
            }
            toast.success("Success",{
                description: `Task money sended!`,
            });

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
        <div className="flex grid-cols-1 justify-start py-6">
            <Card className="p-4">
                <CardTitle>Send money to all users</CardTitle>
                <form onSubmit={handleSubmit} className="flex items-center space-x-2 py-6 pb-0">

                    <Input
                        className="flex-grow px-4 py-2 border rounded-md focus:ring-2 focus:ring-blue-500"
                        placeholder="Enter amount"
                        value={amount === '' ? '' : amount} // Управляемое состояние
                        onChange={(e) => setMoney(e.target.value === '' ? '' : e.target.value)}
                    />
                    <Button type="submit" className="px-6 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                        Send
                    </Button>
                </form>
            </Card>
        </div>
    );
}
