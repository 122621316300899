import {columns, LastPromo, LastPromoTable} from "../new-promo/last-promo-table"; // Импортируем колонки для второй таблицы
import {useApi} from "../../api-provider/api-context";
import React, {useEffect, useState} from "react";
import {NewPromoForm} from "../new-promo/new-promo-form";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


// Функция для получения данных для LastPromo
async function getLastPromoStat(apiUrl: string): Promise<LastPromo[]> {
    const response = await FetchWithAuth(`${apiUrl}/admin/getlastpromo`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(`Ошибка при запросе: ${response.status}`);
    }

    const data = await response.json();
    return data;
}



const NewPromoPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [lastPromoData, setLastPromoData] = useState<LastPromo[]>([]); // Для LastPromo
    const [error, setError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [promoIdToDelete, setPromoIdToDelete] = useState<number | null>(null);

    useEffect(() => {
        const fetchData = async () => {

            setError(null);

            try {
                const lastPromo = await getLastPromoStat(apiUrl); // Получаем данные для LastPromo
                setLastPromoData(lastPromo); // Устанавливаем данные для LastPromo
            } catch (err) {
                setError("Ошибка при загрузке данных");
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            } finally {

            }
        };

        fetchData();
    }, [apiUrl, error]);

    const handleDeletePromo = async () => {
        toast.info(`Удаление промо ${promoIdToDelete} как только Женя сделает мне ручку`) // Перенаправление на страницу редактирования задачи
    };

    // Функция для открытия диалогового окна
    const openDeleteDialog = (id: number) => {
        setPromoIdToDelete(id);
        setOpen(true);
    };

    const refreshAllData = async () => {
        try {
            const promo = await getLastPromoStat(apiUrl);
            setLastPromoData(promo);
        } catch (error) {
            console.error("Ошибка при обновлении данных:", error);
            setError("Ошибка при обновлении данных");
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
            <div className="text-lg text-muted-foreground justify-between">
            {lastPromoData.length > 0 ? (
                    <div className="flex flex-row">
                        <NewPromoForm onSend={refreshAllData}/>
                        <LastPromoTable columns={columns} data={lastPromoData} onDelete={openDeleteDialog}/>
                        {/* Диалоговое окно подтверждения удаления */}
                        <AlertDialog open={open} onOpenChange={setOpen}>
                            <AlertDialogContent>
                                <AlertDialogHeader>
                                    <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                                    <AlertDialogDescription>
                                        This action cannot be undone. This will permanently delete promo.
                                    </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                    <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                                    <AlertDialogAction onClick={handleDeletePromo}>Delete</AlertDialogAction>
                                </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialog>
                    </div>

            ) : (
                <div>no view data</div>
            )}
            </div>
    );
};

export default NewPromoPage;
