import React, {useEffect, useState} from "react";
import {useApi} from "@/pages/api-provider/api-context";
import {columns, TaskInfo, TasksTable} from "@/pages/tasks/all-tasks/tasks-table";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {InitNewTasksBtn} from "@/pages/tasks/new-task/init-new-tasks-btn";
import {toast} from "sonner";
import {Dialog, DialogContent} from "@/components/ui/dialog";
import EditTaskPage from "@/pages/tasks/edit-task/edit-task-page";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


// Функция для получения данных
async function getAllTasks(apiUrl: string): Promise<TaskInfo[]> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-tasks`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        throw error;
    }
}


const AllTasksPage: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [taskIdToDelete, setTaskIdToDelete] = useState<number | null>(null);
    const [taskIdToEdit, setTaskIdToEdit] = useState<number | null>(null);
    const { apiUrl } = useApi(); // Вызов хука внутри компонента
    const [data, setData] = useState<TaskInfo[]>([]); // Состояние для данных
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибок
    const [isDialogOpen, setIsDialogOpen] = useState(false); // Состояние для диалога

    // useEffect для загрузки данных при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getAllTasks(apiUrl); // Передаём apiUrl как параметр
                setData(data);
            } catch (err) {
                setError("Ошибка при загрузке данных");
                console.error(err)
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${err}`,
                })
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]); // Добавляем apiUrl в зависимости, чтобы перезагружать данные при его изменении

    const refreshAllData = async () => {
        try {
            const promo = await getAllTasks(apiUrl);
            setData(promo);
        } catch (error) {
            console.error("Update data:", error);
            setError("Update data");
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    // Функция для открытия диалогового окна
    const openDeleteDialog = (id: number, type: string) => {
        if (type === "subscribe") {
            setTaskIdToDelete(id);
            setOpen(true);
        }
        if (type === "daily") {
            toast.warning("Oops!", {
                description: `Need more permissions to delete task with type: ${type}`,
            })
        }
    };

    const handleDeleteTask = async () => {
        if (taskIdToDelete === null) return; // Проверка на случай, если id не установлен
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/delete-task`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    task_id: taskIdToDelete,
                }),
            });

            // Проверка статуса ответа
            if (!response.ok) {
                throw new Error(`Ошибка при запросе: ${response.status}`);
            }

            // Проверка результата удаления задачи
            if (response.ok) {
                toast.success("Success",{
                    description: `Task deleted!`,
                });
                refreshAllData(); // Обновляем данные после удаления
            } else {
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            }
        } catch (error) {
            console.error("Update data:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console!`,
            })
        } finally {
            setOpen(false); // Закрываем диалоговое окно
            setTaskIdToDelete(null); // Сбрасываем id задачи
        }
    };

    const handleEditTask = (id: number) => {
        // Получите данные задачи по ID (пример функции для получения данных)
        setTaskIdToEdit(id)
        setIsDialogOpen(true); // Откройте диалог
    };

    const handleCloseModal = () => {
        setIsDialogOpen(false);
    }


    const handleCopyTask = async (id: number) => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/copy-task`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include", // Для отправки куки
                body: JSON.stringify({
                    task_id: id, // `Number` не нужен, если id уже число
                }),
            });

            // Проверка статуса ответа
            if (!response.ok) {
                throw new Error(`Send data: ${response.status}`);
            }

            toast.success("Success",{
                description: `Task copied!`,
            });
            refreshAllData();

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
        <div className="w-full h-full">
                <div className="w-full p-4">
                    <div>
                        {loading ? (
                            <div>Загрузка...</div>
                        ) : error ? (
                            <div>{error}</div>
                        ) : data.length > 0 ? (

                            <div className="w-full">
                                <InitNewTasksBtn />
                                <TasksTable columns={columns} data={data} onEdit={handleEditTask} onCopy={handleCopyTask}
                                            onDelete={openDeleteDialog}/>
                                {/* Диалоговое окно подтверждения удаления */}
                                <AlertDialog open={open} onOpenChange={setOpen}>
                                    <AlertDialogContent>
                                        <AlertDialogHeader>
                                            <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                                            <AlertDialogDescription>
                                                This action cannot be undone. This will permanently delete your task
                                                and will delete your data from our servers.
                                            </AlertDialogDescription>
                                        </AlertDialogHeader>
                                        <AlertDialogFooter>
                                            <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                                            <AlertDialogAction onClick={handleDeleteTask}>Delete</AlertDialogAction>
                                        </AlertDialogFooter>
                                    </AlertDialogContent>
                                </AlertDialog>

                                {/*Edit dialog*/}
                                {/* Диалог для редактирования задачи */}
                                <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                                    <DialogContent className="max-w-fit">
                                        <EditTaskPage task_id={taskIdToEdit} onSaveChanges={refreshAllData} closeModal={handleCloseModal}/>
                                    </DialogContent>
                                </Dialog>
                            </div>
                        ) : (
                            <div>no data</div>
                        )}
                    </div>
                </div>
        </div>

    );
};

export default AllTasksPage;
