import React, {useEffect, useState} from "react";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import AllGoodsTable, {Good} from "@/pages/fortune/shop/goods/all-goods-table";
import {useApi} from "@/pages/api-provider/api-context";
import NewGoodForm from "@/pages/fortune/shop/goods/new-good-form";
import {FormData} from "@/pages/tasks/new-task/new-task-form";
import {Dialog, DialogContent} from "@/components/ui/dialog";
import EditGoodPage from "@/pages/fortune/shop/goods/edit-good-page";



// Функция для получения промо-данных
async function getGoods(apiUrl: string): Promise<Good[]> {
    const response = await FetchWithAuth(`${apiUrl}/admin/get-all-goods`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
        credentials: 'include',
    });

    if (!response.ok) {
        throw new Error(`Ошибка при запросе: ${response.status}`);
    }

    const data = await response.json();

    return data;
}

const AllGoodsPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [goods, setGoods] = useState<Good[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [open, setOpen] = useState(false);
    const [goodIDToEdit, setGoodIdToEdit] = useState<number | null>(null);
    const [goodIDToDelete, setGoodIdToDelete] = useState<number | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const goods = await getGoods(apiUrl);
                setGoods(goods);

            } catch (err) {
                setError("Ошибка при загрузке данных");
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    const handleDeleteGood = async () => {
        if (goodIDToDelete === null) return; // Проверка на случай, если id не установлен
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/delete-good`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({
                    good_id: goodIDToDelete,
                }),
            });

            // Проверка статуса ответа
            if (!response.ok) {
                throw new Error(`Ошибка при запросе: ${response.status}`);
            }

            // Проверка результата удаления задачи
            if (response.ok) {
                toast.success("Success",{
                    description: `Good deleted!`,
                });
            } else {
                console.error(error);
                toast.warning("Oops!", {
                    description: `Something went wrong, checkout console! ${error}`,
                })
            }
        } catch (error) {
            console.error("Update data:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console!`,
            })
        } finally {
            setOpen(false); // Закрываем диалоговое окно
            setGoodIdToDelete(null); // Сбрасываем id задачи
        }
        refreshAllData();
    };

    const handleEditTask = (id: number) => {
        // Получите данные задачи по ID (пример функции для получения данных)
        setGoodIdToEdit(id)
        setIsDialogOpen(true); // Откройте диалог
    };

    const handleCloseModal = () => {
        setIsDialogOpen(false);
    }

    // Функция для открытия диалогового окна
    const openDeleteDialog = (id: number) => {
        setGoodIdToDelete(id);
        setOpen(true);
    };

    const refreshAllData = async () => {
        try {
            const goods = await getGoods(apiUrl);
            setGoods(goods);
        } catch (error) {
            console.error("Update data:", error);
            setError("Update data");
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    if (loading) {
        return (<div>loading</div>)
    }

    return (
        <div className="text-lg">
                <div className="grid grid-cols-1 gap-4">
                    <NewGoodForm update={refreshAllData} isEditMode={false} onSubmitSuccess={refreshAllData} />
                    <AllGoodsTable data={goods ? goods : []} onDelete={openDeleteDialog} onEdit={handleEditTask} />
                    {/* Диалоговое окно подтверждения удаления */}
                    <AlertDialog open={open} onOpenChange={setOpen}>
                        <AlertDialogContent>
                            <AlertDialogHeader>
                                <AlertDialogTitle>Вы уверены?</AlertDialogTitle>
                                <AlertDialogDescription>
                                    Это действие невозможно отменить. Это навсегда удалит вашу задачу
                                    и удалит ваши данные с наших серверов.
                                </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                                <AlertDialogCancel onClick={() => setOpen(false)}>Отмена</AlertDialogCancel>
                                <AlertDialogAction onClick={handleDeleteGood}>Удалить</AlertDialogAction>
                            </AlertDialogFooter>
                        </AlertDialogContent>
                    </AlertDialog>

                    <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
                        <DialogContent className="max-w-fit">
                            <EditGoodPage good_id={goodIDToEdit} onSaveChanges={refreshAllData} closeModal={handleCloseModal} />
                        </DialogContent>
                    </Dialog>
                </div>
        </div>
    );
};

export default AllGoodsPage;
