import React from "react";
import ReactDOM from "react-dom/client";
import {ThemeProvider} from "./components/theme-provider";
import {RouterProvider} from "react-router-dom";
import "./index.css";
import {router} from "./routes";
import {ApiProvider} from './pages/api-provider/api-context'
import {Toaster} from "sonner";
import CatPeek from "@/components/fun/cat-peek";
import ScrollDisabler from "@/components/utils/scroll-disabler";


const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);



root.render(

    <React.StrictMode>
        <ThemeProvider>
            <div className="py-8 container flex justify-center h-auto">
                <CatPeek />
                <ScrollDisabler />
            <ApiProvider>
            <RouterProvider router={router}></RouterProvider>

            </ApiProvider>
                <Toaster theme={"system"} closeButton richColors invert position="top-right" visibleToasts={1} />
            </div>
        </ThemeProvider>
    </React.StrictMode>
);
