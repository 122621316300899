import React from "react";
import {
    FileUp,
    LayoutList, StickyNote
} from "lucide-react";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { Card } from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function TasksMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[450px] py-1">
                <CommandList>
                    <CommandGroup  className="text-lg font-bold" heading="TASKS" />
                    <CommandSeparator/>
                    <CommandGroup heading="Actions">
                        <CommandItem onSelect={() => onSelectCommand("new-task")}>
                            <StickyNote size={20} className="mr-2"  />
                            <span>New task</span>
                        </CommandItem>
                        <CommandItem onSelect={() => onSelectCommand("new-partner-icon")}>
                            <FileUp size={20} className="mr-2"/>
                            <span>Upload partner icon</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator/>
                    <CommandGroup heading="Information">
                        <CommandItem onSelect={() => onSelectCommand("all-tasks")}>
                            <LayoutList size={20} className="mr-2" />
                            <span>All tasks</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />


                </CommandList>
            </Command>
        </Card>
    );
}
