import React, {useState} from 'react';

import {ResizableHandle, ResizablePanel, ResizablePanelGroup,} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import {LockScreenPage} from "@/pages/settings/lock-screen/lock-screen-page";
import SettingsMenu from "@/pages/settings/settings-menu";
import {SetupTonBankPage} from "@/pages/settings/ton/setup-bank/setup-bank-page";
import {ChangeRefLinkPage} from "@/pages/settings/change-ref-link/change-ref-link-page";


// Основной компонент
const SettingsMainPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");

    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "lock-screen":
                return <LockScreenPage />;
            case "setup-bank":
                return <SetupTonBankPage/>
            case "change-ref-link":
                return <ChangeRefLinkPage />
            default:
                return <div>some main info</div>;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <SettingsMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default SettingsMainPage;
