import {useForm} from "react-hook-form";
import {Button} from "@/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {Input} from "@/components/ui/input";
import {useApi} from "../../api-provider/api-context";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

interface PromoProps {
    onSend: () => void; // Пропс с типом функции
}

export function NewPromoForm({ onSend }: PromoProps) { // Принимаем onCreate как пропс
    const { apiUrl } = useApi();

    const form = useForm({});

    const nounIDValue = form.watch("nounID");
    const promoRewardValue = form.watch("promoReward");
    const activeTimeValue = form.watch("activeTime");

    const isNounIDValid = nounIDValue !== undefined && Number(nounIDValue) > 0;
    const isPromoRewardValid = !isNaN(parseFloat(promoRewardValue)) && parseFloat(promoRewardValue) >= 0;
    const isActiveTimeValid = !isNaN(parseFloat(activeTimeValue)) && parseFloat(activeTimeValue) >= 0;

    const isFormValid = isNounIDValid && isPromoRewardValid && isActiveTimeValid;

    const onSubmit = async (values: any) => {
        try {
            const payload = {
                reward: Number(values.promoReward),
                hours: Number(values.activeTime),
                nounId: Number(values.nounID),
            };

            const response = await FetchWithAuth(`${apiUrl}/admin/generatepromo`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }




            form.reset({
                noun: "",
                nounID: "",
                promoReward: "",
                activeTime: "",
            });

            toast.success("Succes!", {
                description: "Promocode created!"
            })

            onSend();
        } catch (error) {
            console.error("Ошибка при отправке данных:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    const handleGetNounID = async () => {
        const nounValue = form.getValues("noun");
        if (!nounValue) {
            console.error("Noun is required to get Noun ID.");

            return;
        }

        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/create-noun`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify({ word: nounValue }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            const nounID = Number(data.id);
            form.setValue("nounID", nounID);
        } catch (error) {
            console.error("Ошибка при получении данных:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    const handleRandomWord = async () => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/random-word`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            form.setValue("noun", data.word);
            form.setValue("nounID", Number(data.id));
        } catch (error) {
            console.error("Ошибка при получении случайного слова:", error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
        <div className="flex justify-center">
            <div className="w-full max-w-md">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
                        <Card>
                            <CardHeader className="text-lg font-bold">Create New Promo</CardHeader>
                            <CardContent>

                                <FormField
                                    control={form.control}
                                    name="noun"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Noun</FormLabel>
                                            <div className="flex items-center space-x-2">
                                                <FormControl className="flex-1">
                                                    <Input placeholder="Enter noun" {...field} />
                                                </FormControl>
                                                <Button
                                                    type="button"
                                                    onClick={handleGetNounID}
                                                    className="flex-shrink-0 w-1/3"
                                                >
                                                    Set Promo Word
                                                </Button>
                                                <Button
                                                    type="button"
                                                    onClick={handleRandomWord}
                                                    className="flex-shrink-0 w-1/3 bg-blue-500 hover:bg-blue-600 text-white"
                                                >
                                                    Random Word
                                                </Button>
                                            </div>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="promoReward"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Promo Reward</FormLabel>
                                            <FormControl>
                                                <Input type="number" placeholder="Enter reward" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    control={form.control}
                                    name="activeTime"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>Active Time</FormLabel>
                                            <FormControl>
                                                <Input type="number" placeholder="Enter active time in hours" {...field} />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />

                                <div className="mt-4 text-gray-600">
                                    <p>1. Enter noun and click Set Promo Word</p>
                                    <p>or click get Random Word</p>
                                    <p>2. Enter Promo Reward and Active Time (hours)</p>
                                    <p>3. Click green btn Create!</p>
                                </div>

                            </CardContent>
                            <CardFooter>
                                <Button
                                    type="submit"
                                    className={`w-full ${isFormValid ? 'bg-green-500' : 'bg-gray-400'}`}
                                    disabled={!isFormValid}
                                >
                                    CREATE
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>
            </div>
        </div>
    );
}
