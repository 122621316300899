import React, {useState} from "react";
import {Button} from "@/components/ui/button";
import {Card, CardContent, CardFooter, CardHeader, CardTitle,} from "@/components/ui/card";

import {Input} from "@/components/ui/input";
import {Label} from "@/components/ui/label";
import {useNavigate} from "react-router-dom";
import EnvSelector from "../api-provider/env-selector";
import {useApi} from '../api-provider/api-context';
import {toast} from "sonner";


export const description =
  "A simple login form with email and password. The submit button says 'Sign in'.";



export function LoginForm() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Хук для навигации
  const { apiUrl } = useApi();


  // Обработчик отправки формы
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Предотвращаем перезагрузку страницы
    
    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Важный параметр для работы с куки
        body: JSON.stringify({
          login: login,
          password: password,
        }),
      });

      if (response.ok) {
        toast.success("Succes!", {
          description: "You are logged in!",
        })

        // После успешного логина куки должны быть установлены сервером
        navigate("/users"); // Перенаправляем пользователя на страницу /users

      } else if (response.status === 401) {
        alert("Invalid login or password");
      } else {
        alert("An error occurred");
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("An unexpected error occurred");
    }
  };

  return (
    <Card className="w-full max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <div>
          <EnvSelector />
        </div>
      </CardHeader>
      <form onSubmit={handleSubmit}>
        <CardContent className="grid gap-4">
          <div className="grid gap-2">
            <Label htmlFor="login">Login</Label>
            <Input
              id="login"
              type="text"
              placeholder="login"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
              required
            />
          </div>
          <div className="grid gap-2">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </CardContent>
        <CardFooter>
          <Button className="w-full" type="submit">
            Login
          </Button>
        </CardFooter>
      </form>

      
    </Card>
  );
}


