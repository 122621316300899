import React, {useState} from "react";
import {useApi} from "@/pages/api-provider/api-context";
import {Button} from "@/components/ui/button";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


export function InitNewTasksBtn() {
    const { apiUrl } = useApi();
    const [loading, setLoading] = useState(false); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибки
    const [success, setSuccess] = useState<string | null>(null); // Состояние успеха
    const [open, setOpen] = useState(false);

    // Remove the event parameter since it's not needed here
    const handleSubmit = async () => {
        setLoading(true); // Устанавливаем состояние загрузки
        setError(null); // Сбрасываем ошибку
        setSuccess(null); // Сбрасываем успешное сообщение

        const timeout = 60000; // Таймаут в миллисекундах (1 минута)

        try  {
            setLoading(true); // Включаем индикатор загрузки
            // Указываем явно, что ожидаем тип Response
            const response: Response = await Promise.race<Response>([
                FetchWithAuth(`${apiUrl}/admin/tasks/init-active`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                }),
                new Promise<Response>((_, reject) =>
                    setTimeout(() => reject(new Error('Request timeout')), timeout)
                )
            ]);

            if (!response.ok) {
                throw new Error('Send data');
            }

            const data = await response.json(); // Если структура данных неизвестна, можно использовать any

            toast.success('Tasks inited!', {
                description: `Server response: ${JSON.stringify(data)}`,
            });
        } catch (error: any) {
            console.error('Ошибка:', error);
            toast.error('Oops!', {
                description: `Something went wrong, check console! ${error.message}`,
            });
        } finally {
            setLoading(false);
            setOpen(false)// Выключаем индикатор загрузки
        }
    };

    return (
        <div>
            <Button variant="default" onClick={() => setOpen(true)} disabled={loading}>
                {loading ? 'process...' : 'Init new tasks'}
            </Button>

            {error && <div className="text-red-500 mt-2">{error}</div>}
            {success && <div className="text-green-500 mt-2">{success}</div>}
            {/* Диалоговое окно подтверждения */}
            <AlertDialog open={open} onOpenChange={setOpen}>
                <AlertDialogContent>
                    <AlertDialogHeader>
                        <AlertDialogTitle>Attention!</AlertDialogTitle>
                        <AlertDialogDescription>
                            This action cannot be undone. Start init new tasks now?
                        </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                        <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                        <AlertDialogAction onClick={handleSubmit} disabled={loading}>
                            {loading ? 'process...' : 'Init'}
                        </AlertDialogAction>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
        </div>
    );
}
