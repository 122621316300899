// const FetchWithAuth = async (url: string, options?: RequestInit): Promise<Response> => {
//     try {
//         let response = await fetch(url, {
//             credentials: "include", // Включаем куки
//             ...options,
//         });
//
//         const baseUrl = new URL(url).origin;
//         console.log(baseUrl)
//
//         if (response.status === 401) {
//             // Пытаемся обновить токен
//             window.location.href = "/login";
//             throw new Error("Не удалось обновить токен, пользователь перенаправлен на /login");
//
//         }
//
//         return response; // Возвращаем ответ, если запрос успешен
//     } catch (error) {
//         console.error("Ошибка при выполнении запроса:", error);
//         throw error; // Бросаем ошибку дальше
//     }
// };
//
// export default FetchWithAuth;



const FetchWithAuth = async (url: string, options?: RequestInit): Promise<Response> => {
    try {
        // Парсим базовый URL до первого "/"
        const baseUrl = new URL(url).origin;

        let response = await fetch(url, {
            credentials: "include", // Включаем куки
            ...options,
        });

        if (response.status === 401) {
            // Пытаемся обновить токен
            const refreshResponse = await fetch(`${baseUrl}/refresh`, {
                method: "POST",
                credentials: "include", // Включаем куки для обновления
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (refreshResponse.ok) {
                // Если обновление успешно, повторяем оригинальный запрос
                response = await fetch(url, {
                    credentials: "include", // Включаем куки
                    ...options,
                });
            } else {
                // Если обновление не удалось, редирект на страницу логина
                window.location.href = "/login";
                throw new Error("Не удалось обновить токен, пользователь перенаправлен на /login");
            }
        }

        return response; // Возвращаем ответ, если запрос успешен
    } catch (error) {
        console.error("Ошибка при выполнении запроса:", error);
        throw error; // Бросаем ошибку дальше
    }
};

export default FetchWithAuth;