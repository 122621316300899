import React, {useEffect, useState} from 'react';
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {useApi} from '../../api-provider/api-context';
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {useForm} from "react-hook-form";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

type RefData = {
    count_referrals: number;
};

interface FormData {
    ref_link: string;
    user_id: number;
}

const SearchRefByUserId = () =>  {
    const {apiUrl} = useApi();
    const [referrals, setReferrals] = useState<RefData | null>(null);

    const form = useForm<FormData>({
        defaultValues: {
            ref_link: '',
            user_id: 0
        },
    });

    const parseUserIdFromLink = (input: string) => {
        if (!isNaN(Number(input))) {
            form.setValue("user_id", Number(input));
            form.setValue("ref_link", input);
            return;
        }

        const refMatch = input.match(/ref=(\d+)/);
        if (refMatch && refMatch[1]) {
            const userId = Number(refMatch[1]);
            if (!isNaN(userId) && userId > 0) {
                form.setValue("user_id", userId);
            } else {
                form.setValue("user_id", NaN);
            }
            form.setValue("ref_link", input);
        } else {
            form.setValue("user_id", NaN);
            form.setValue("ref_link", input);
        }

        form.trigger("user_id");
    };

    // useEffect для отслеживания изменений ref_link
    useEffect(() => {
        const currentLink = form.watch("ref_link");
        if (currentLink) {
            parseUserIdFromLink(currentLink);
        }
    }, [form.watch("ref_link")]); // Добавляем конкретное значение ref_link в массив зависимостей

    const onSubmit = async (values: FormData) => {
        parseUserIdFromLink(values.ref_link);

        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/get-referrals`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    ref_link: values.ref_link,
                    user_id: values.user_id,
                }),
                credentials: 'include'
            });

            if (!response.ok) {
                throw new Error('Send data');
            }

            const data: RefData = await response.json();
            setReferrals(data);
        } catch (error) {
            console.error('Error:', error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            });
        }
    };

    return (
        <div className="flex p-4">
            <div className="w-full max-w-lg">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <Card>
                            <CardHeader>Check user's referrals</CardHeader>
                            <CardContent>
                                <div>
                                    <div className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                        <FormField
                                            control={form.control}
                                            name="ref_link"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-xs">Link URL or user ID</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            className="h-9"
                                                            placeholder="ref link or user ID"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage />
                                                </FormItem>
                                            )}
                                        />
                                        {referrals !== null && (
                                            <CardContent>
                                                <p>Ref count: {referrals.count_referrals}</p>
                                            </CardContent>
                                        )}
                                    </div>
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button
                                    type="submit"
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white"
                                >
                                    CHECK
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>
            </div>
        </div>
    );
}

export default SearchRefByUserId;
