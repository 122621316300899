"use client"
import * as React from "react"
import {Button} from "@/components/ui/button"
import {ArrowUpDown} from "lucide-react"
//get-all-users
import {
    ColumnDef,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table"

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table"

// block users table
export type Session = {
    all_time: number,
    date_in: number,
    duration: string,
    id: number,
    user_id: number
}
  
// table columns
export const columns: ColumnDef<Session>[] = [
    {
      accessorKey: "id",
      header: "id",
    },
    {
      accessorKey: "user_id",
      header: "user_id",
    },
    {
        accessorKey: "date_in",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              date_in
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
    },
    {
        accessorKey: "duration",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              duration
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
      },
    {
        accessorKey: "all_time",
        header: ({ column }) => {
          return (
            <Button
              variant="ghost"
              onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
            >
              all_time
              <ArrowUpDown className="ml-2 h-4 w-4" />
            </Button>
          )
        },
    },
   
];

interface DataTableProps<TData, TValue> {
    columns: ColumnDef<TData, TValue>[]
    data: TData[]
  }
   
  export function SessionsTable<TData, TValue>({
                                                   columns,
                                                   data,
                                               }: DataTableProps<TData, TValue>) {
      const [sorting, setSorting] = React.useState<SortingState>([])
      const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
          []
      )

      const table = useReactTable({
          data,
          columns,
          getCoreRowModel: getCoreRowModel(),
          onSortingChange: setSorting,
          getSortedRowModel: getSortedRowModel(),
          onColumnFiltersChange: setColumnFilters,
          getFilteredRowModel: getFilteredRowModel(),
          state: {
              sorting,
              columnFilters,
          },
      })

      return (
          <div className="h-full">
              <div className="rounded-md border">
                  <Table  >
                      <TableHeader >
                          {table.getHeaderGroups().map((headerGroup) => (
                              <TableRow key={headerGroup.id} >
                                  {headerGroup.headers.map((header) => {
                                      return (
                                          <TableHead key={header.id}  className="text-center">
                                              {header.isPlaceholder
                                                  ? null
                                                  : flexRender(
                                                      header.column.columnDef.header,
                                                      header.getContext()
                                                  )}

                                          </TableHead>
                                      )
                                  })}
                              </TableRow>
                          ))}
                      </TableHeader>
                      <TableBody>
                          {table.getRowModel()?.rows?.length ? (
                              table.getRowModel().rows.map((row) => (
                                  <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                                      {row.getVisibleCells().map((cell) => (
                                          <TableCell key={cell.id} className="text-center">
                                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                          </TableCell>
                                      ))}
                                  </TableRow>
                              ))
                          ) : (
                              <TableRow>
                                  <TableCell colSpan={columns.length} className="h-24 text-center">
                                      No results.
                                  </TableCell>
                              </TableRow>
                          )}
                      </TableBody>
                  </Table>
              </div>
          </div>
      )
  };