import React from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table";

// Тип данных для таблицы
export type User = {
    balance: number;
    energy: number;
    language: string;
    referrals_count: number;
    tg_id: number;
    username: string;
};

interface TableComponentProps {
    data: User[];

}

const DynamicUsersTable: React.FC<TableComponentProps> = ({ data }) => {
    const keys = data.length > 0 ? Object.keys(data[0]) : [];

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    {keys.map((key) => (
                        <TableHead key={key} className="text-center">
                            {key}
                        </TableHead>
                    ))}
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.map((user, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {keys.map((key) => (
                            <TableCell key={key} className="text-center">
                                {String(user[key as keyof User])}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default DynamicUsersTable;
