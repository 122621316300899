import React from "react";
import {MessageSquarePlusIcon, PiggyBank, Settings} from "lucide-react";
import {Command, CommandGroup, CommandItem, CommandList, CommandSeparator,} from "@/components/ui/command";
import {Card} from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function FortuneWheelMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[450px] py-1">
                <CommandList>
                    <CommandGroup heading="Wheel">
                        <CommandItem onSelect={() => onSelectCommand("fortune-settings")}>
                            <Settings  size={20} className="mr-2"/>
                            <span>Settings</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />
                </CommandList>
                <CommandGroup heading="Shop">
                    <CommandItem onSelect={() => onSelectCommand("new-goods")}>
                        <MessageSquarePlusIcon size={20} className="mr-2" />
                        <span>New goods</span>
                    </CommandItem>
                </CommandGroup>
            </Command>
        </Card>
    );
}
