import React, {useState} from 'react';
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {useApi} from '../../api-provider/api-context';
import {Card, CardTitle} from "@/components/ui/card";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

export function UploadPartnerIcon() {
    const [file, setFile] = useState<File | null>(null); // Для хранения файла
    const { apiUrl } = useApi();

    // Обработчик отправки формы
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault(); // Предотвратить перезагрузку страницы

        // Проверим наличие файла
        if (!file) {
            console.error('Файл не выбран');
            return;
        }

        // Создаем объект FormData для отправки только файла
        const formData = new FormData();
        formData.append('file', file); // Добавляем файл в FormData

        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/partner-icon`, {
                method: 'POST',
                body: formData, // Отправляем FormData
                credentials: 'include',
            });

            if (!response.ok) {
                throw new Error('Ошибка при отправке файла');
            }

            toast.success("Success",{
                description: `Photo uploaded!`,
            });
        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    // Обработчик для выбора файла
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0] || null;
        setFile(selectedFile);
    };

    return (
        <div className="flex grid-cols-1 justify-start py-6">
            <Card className="p-4">
                <CardTitle>Upload partner icon</CardTitle>
                <form onSubmit={handleSubmit} className="flex flex-col max-w-sm space-y-4 py-6 pb-0">
                    <Input
                        type="file"
                        accept="image/*" // Ограничиваем тип файла изображениями
                        onChange={handleFileChange} // Обработчик выбора файла
                    />
                    <Button type="submit">Upload</Button>
                </form>
            </Card>
        </div>
    );
}