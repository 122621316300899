import React, {useState} from 'react';
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {useApi} from '../../api-provider/api-context';
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {useForm} from "react-hook-form";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {toast} from "sonner";
import {Users} from "@/pages/users/all-users/users-table";
import DynamicUsersTable from "@/pages/users/search-user/dynamic-users-table";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


interface FormData {
    username: string;
}

const SearchByUserName = () =>  {
    const {apiUrl} = useApi();
    const [users, setUsers] = useState<Users | null>(null); // Хранение одного объекта рефералов

    const form = useForm<FormData>({
        defaultValues: {
            username: '',
        },
    });

    const onSubmit = async (values: FormData) => {

        try  {

            const response = await FetchWithAuth(`${apiUrl}/admin/get-user`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username: values.username,
                }),
                credentials: 'include' // Отправляем userId в теле запроса
            });

            if (!response.ok) {
                throw new Error('Ошибка при отправке данных');
            }

            const data: Users = await response.json();
            setUsers(data) // Сохраняем объект рефералов

        } catch (error) {
            console.error('Ошибка:', error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
        <div>
        <div className="flex p-4">
            <div className="w-full max-w-lg">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <Card>
                            <CardHeader >Search user</CardHeader>
                            <CardContent>
                                <div >
                                    <div className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                        <FormField
                                            control={form.control}
                                            name="username"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-xs">Select by username</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            className="h-9"
                                                            placeholder="enter username"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                    </div>
                                </div>

                            </CardContent>
                            <CardFooter>
                                <Button
                                    type="submit"
                                    className="w-full bg-blue-500 hover:bg-blue-600 text-white"
                                >
                                    CHECK
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>


            </div>

        </div>
            {users && (
                <DynamicUsersTable data={users.users ? users.users : []} />
            )}
        </div>
    );
}

export default  SearchByUserName;