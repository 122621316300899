import React, { useEffect, useState } from "react";
import { useApi } from "@/pages/api-provider/api-context";
import { toast } from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import { Bar, BarChart, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from "recharts";

// Интерфейс для данных статистики приложения
interface AppStatistics {
    ton_bank: number;
    total_spins: number;
    total_ton: number;
    total_stars: number;
    total_fortune: number;
}

// Интерфейс для данных гистограммы
interface Histo {
    index: string;
    value: number;
}

// Функция для получения данных статистики приложения
async function getAppStat(apiUrl: string): Promise<AppStatistics> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-stat`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }
        return await response.json();
    } catch (error) {
        console.error(error);
        throw error;
    }
}

// Функция для получения данных гистограммы
async function getHisto(apiUrl: string): Promise<Histo[]> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-distribution`, {
            method: "GET",

            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        // Получаем данные и преобразуем их в нужный формат
        const data = await response.json();
        return Object.keys(data).map((key) => ({
            index: key,  // Индекс (например, месяц)
            value: data[key],  // Значение
        }));
    } catch (error) {
        console.error(error);
        throw error;
    }
}

const AppStatPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [data, setData] = useState<AppStatistics | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [histo, setHisto] = useState<Histo[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getAppStat(apiUrl);
                const histoData = await getHisto(apiUrl);

                // Устанавливаем данные в состояние
                setHisto(histoData);
                setData(data);
            } catch (err) {
                setError("Ошибка при загрузке данных");
                console.error(err);
                toast.warning("Oops!", {
                    description: `Something went wrong, check console! ${err}`,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="w-full h-full">
            <div>
                <h2>App Statistics</h2>
                <ul>
                    <li>Ton bank: {data?.ton_bank}</li>
                    <li>Winned TON: {data?.total_ton}</li>
                    <li>Total spins: {data?.total_spins}</li>
                    <li>Spended STARS: {data?.total_stars}</li>
                    <li>Total fortune: {data?.total_fortune}</li>
                </ul>
            </div>

            {/* Гистограмма */}
            <div className="mt-8">
                <h3>Distribution Histogram</h3>
                <BarChart width={500} height={300} data={histo}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="index" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="value" fill="#8884d8" />
                </BarChart>
            </div>
        </div>
    );
};

export default AppStatPage;
