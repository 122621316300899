import {createBrowserRouter, Outlet, useLocation} from "react-router-dom";
import App from "./pages";
import SessionsPage from "@/pages/statistics/sessions/sessions-page";
import {LoginForm} from "@/pages/login/login-page";
import ProtectedRoute from "@/pages/login/protected-route";
import Nav from "./components/nav";
import LogoutButton from "@/pages/login/logout";
import StatisticsMainPage from "@/pages/statistics/statistics-main-page";
import TasksMainPage from "@/pages/tasks/tasks-main-page";
import StoriesMainPage from "@/pages/stories/stories-main-page";
import UsersPage from "@/pages/users/users-page";
import PromocodesMainPage from "@/pages/promocodes/promocodes-main-page";
import FortuneWheelMainPage from "@/pages/fortune/fortune-wheel-main-page";
import React from "react";
import SettingsMainPage from "@/pages/settings/settings-main-page";

// Компонент-обертка для отображения Nav
const LayoutWithNav = () => {
    const location = useLocation();

    // Определяем, нужно ли скрыть Nav (например, на странице /login)
    const hideNav = location.pathname === "/login";

    return (
        <div className="h-screen py-8 container">
            {/* Условный рендеринг Nav */}
            {!hideNav && <Nav />}
            <Outlet />
        </div>
    );
};

// Определяем маршруты с использованием обертки
const router = createBrowserRouter([
    {
        element: <LayoutWithNav />, // Оборачиваем все маршруты в LayoutWithNav
        children: [
            {
                path: "/",
                element: (
                    <ProtectedRoute>
                        <App />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/sessions",
                element: (
                    <ProtectedRoute>
                        <SessionsPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/settings",
                element: (
                    <ProtectedRoute>
                        <SettingsMainPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/users",
                element: (
                    <ProtectedRoute>
                        <UsersPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/tasks",
                element: (
                    <ProtectedRoute>
                        <TasksMainPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/promocodes",
                element: (
                    <ProtectedRoute>
                        <PromocodesMainPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/logout",
                element: (
                    <ProtectedRoute>
                        <LogoutButton />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/stories",
                element: (
                    <ProtectedRoute>
                        <StoriesMainPage/>
                    </ProtectedRoute>
                ),
            },
            {
                path: "/stat",
                element: (
                    <ProtectedRoute>
                        <StatisticsMainPage />
                    </ProtectedRoute>
                ),
            },
            {
                path: "/fortune",
                element: (
                    <ProtectedRoute>
                        <FortuneWheelMainPage />
                    </ProtectedRoute>
                ),
            },
        ],
    },
    {
        path: "/login",
        element: <LoginForm />,
    },
]);

export { router };
