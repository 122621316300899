import React from "react";
import {
    Users,
    Contact,
    SmilePlus, KeySquare, Plus
} from "lucide-react";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { Card } from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function PromocodesMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[450px] py-1">
                <CommandList>
                    <CommandGroup heading="Actions">
                        <CommandItem onSelect={() => onSelectCommand("new-promo")}>
                            <Plus size={20} className="mr-2"/>
                            <span>New promo</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />
                    <CommandGroup heading="Information">
                        <CommandItem onSelect={() => onSelectCommand("all-promo")}>
                            <KeySquare size={20} className="mr-2"/>
                            <span>Promocodes</span>
                        </CommandItem>
                    </CommandGroup>
                </CommandList>
            </Command>
        </Card>
    );
}
