import React, {useState} from 'react';
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {useApi} from '../../api-provider/api-context';
import {useForm} from "react-hook-form";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage} from "@/components/ui/form";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

interface FormData {
    reflink: string;
}

const ChangeRefLinkForm = () =>  {
    const { apiUrl } = useApi();
    const [loading, setLoading] = useState(false); // Статус загрузки

    const form = useForm<FormData>({
        defaultValues: {
            reflink: '',
        },
    });

    const onSubmit = async (values: FormData) => {
        const timeout = 60000; // Таймаут в миллисекундах (1 минута)

        try  {
            setLoading(true); // Включаем индикатор загрузки
            toast.loading('Sending request...',{
                duration: timeout,
            });

            // Указываем явно, что ожидаем тип Response
            const response: Response = await Promise.race<Response>([
                FetchWithAuth(`${apiUrl}/admin/change-reflink`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ reflink: values.reflink }),
                    credentials: 'include',
                }),
                new Promise<Response>((_, reject) =>
                    setTimeout(() => reject(new Error('Request timeout')), timeout)
                )
            ]);

            if (!response.ok) {
                throw new Error('Ошибка при отправке данных');
            }

            const data = await response.json(); // Если структура данных неизвестна, можно использовать any

            toast.success('Response received!', {
                description: `Server response: ${JSON.stringify(data)}`,
            });
        } catch (error: any) {
            console.error('Ошибка:', error);
            toast.error('Oops!', {
                description: `Something went wrong, check console! ${error.message}`,
            });
        } finally {
            setLoading(false); // Выключаем индикатор загрузки
        }
    };

    return (
        <div>
            <div className="flex p-4">
                <div className="w-full">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                            <FormLabel className="text-xs">Change ref-link</FormLabel>
                            <div className="flex grid-cols-2">
                                <FormField
                                    control={form.control}
                                    name="reflink"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormControl>
                                                <Input
                                                    className="h-9"
                                                    placeholder="enter ref-link"
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage />
                                        </FormItem>
                                    )}
                                />
                                <Button
                                    type="submit"
                                    className="bg-blue-500 hover:bg-blue-600 text-white"
                                    disabled={loading} // Отключаем кнопку во время загрузки
                                >
                                    {loading ? 'Loading...' : 'Change'}
                                </Button>
                            </div>
                        </form>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ChangeRefLinkForm;
