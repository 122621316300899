import React, {useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form"; // Убедитесь, что ваши импорты правильные
import {Input} from "@/components/ui/input";
import {useApi} from "@/pages/api-provider/api-context";
import {Button} from "@/components/ui/button";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

interface FormData {
    image_url: string;
    texts: {
        en: {
            header: string;
            text: string;
            title: string;
        };
        ru: {
            header: string;
            text: string;
            title: string;
        };
    };
}

interface ImageOption {
    title: string; // Название изображения
    url: string;   // URL изображения
}

const getImages = async (apiUrl: string): Promise<ImageOption[]> => {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-images`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const resp = await response.json();
        return resp.images || []; // Убедитесь, что вы возвращаете массив
    } catch (error) {
        console.error(error);
        toast.warning("Oops!", {
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
};

const NewStoryForm: React.FC = () => {
    const { apiUrl } = useApi();
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [imageOptions, setImageOptions] = useState<ImageOption[]>([]);
    const [selectedImageTitle, setSelectedImageTitle] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки

    const form = useForm<FormData>({
        defaultValues: {
            image_url: selectedImage || '',
            texts: {
                en: {
                    header: '',
                    text: '',
                    title: '',
                },
                ru: {
                    header: '',
                    text: '',
                    title: '',
                },
            },
        },
    });


    const [charCounts, setCharCounts] = useState({
        en: { header: 0, text: 0, title: 0 },
        ru: { header: 0, text: 0, title: 0 },
    });

    const maxLength = 999; // Общее ограничение символов для всех полей
    const img = form.watch('image_url')
    const isClear = img === ''

    // Функция для обработки изменений в каждом поле
    const handleChange = (
        lang: 'en' | 'ru',
        fieldName: 'header' | 'text' | 'title',
        onChange: (...args: any) => void,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newValue = event.target.value;

        // Ограничение длины строки
        if (newValue.length <= maxLength) {
            setCharCounts((prevCounts) => ({
                ...prevCounts,
                [lang]: {
                    ...prevCounts[lang],
                    [fieldName]: newValue.length,
                },
            }));

            // Обновляем значение поля в форме
            onChange(event); // Важно: передаём событие `event`, чтобы `react-hook-form` обновил состояние формы
        }
    };

    useEffect(() => {
        const fetchImageOptions = async () => {
            try {
                const images = await getImages(apiUrl);
                setImageOptions(images);


                if (images.length > 0) {
                    const firstImage = images[0].url;
                    setSelectedImage(firstImage);
                    setSelectedImageTitle(images[0].title); // Устанавливаем title для первого изображения
                    form.setValue('image_url', firstImage);
                }
            } catch (error) {
                console.error("Ошибка при получении опций изображений:", error);
                alert("Не удалось загрузить опции изображений.");
            } finally {
                setLoading(false);
            }
        };

        fetchImageOptions();
    }, [apiUrl, form]);

    const handleImageSelect = (url: string) => {
        const selectedOption = imageOptions.find(option => option.url === url); // Находим выбранный элемент
        setSelectedImage(url);
        setSelectedImageTitle(selectedOption ? selectedOption.title : null); // Устанавливаем выбранный title
        form.setValue('image_url', url);
    };

    const onSubmit = async (values: FormData) => {
        try {
            const payload = {
                image_url: values.image_url,
                texts: {
                    en: {
                        header: values.texts.en.header,
                        text: values.texts.en.text,
                        title: values.texts.en.title,
                    },
                    ru: {
                        header: values.texts.ru.header,
                        text: values.texts.ru.text,
                        title: values.texts.ru.title,
                    },
                },
            };

            const response = await FetchWithAuth(`${apiUrl}/admin/new-story`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }


            toast.success("Success",{
                description: `Story created!`,
            });

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
        }
    };

    return (
        <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col space-y-6 pl-1">


                {/* English and Russian Texts in Two Columns */}
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                    {/* English Texts */}
                    <div className="space-y-4">
                        <h2 className="text-lg font-medium mb-2">English Texts</h2>

                        <FormField
                            name="texts.en.header"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Header (EN)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter title"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('en', 'header', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.en.header}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="texts.en.title"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Title (EN)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter title"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('en', 'title', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.en.title}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="texts.en.text"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Text (EN)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter title"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('en', 'text', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.en.text}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>

                    {/* Russian Texts */}
                    <div className="space-y-4">
                        <h2 className="text-lg font-medium mb-2">Russian Texts</h2>
                        <FormField
                            name="texts.ru.header"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Header (RU)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter header"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('ru', 'header', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.ru.header}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="texts.ru.title"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Title (RU)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter title"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('ru', 'title', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.ru.title}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />

                        <FormField
                            name="texts.ru.text"
                            control={form.control}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel className="text-xs">Text (RU)</FormLabel>
                                    <FormControl>
                                        <Input
                                            className="h-9"
                                            placeholder="Enter text"
                                            maxLength={maxLength} // Ограничение символов
                                            value={field.value}
                                            onChange={(event) => handleChange('ru', 'text', field.onChange, event)}
                                        />
                                    </FormControl>
                                    <div className="text-xs text-gray-500">
                                        Осталось символов: {maxLength - charCounts.ru.text}
                                    </div>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                    </div>
                </div>

                {/* Поле для URL изображения */}
                <FormField
                    control={form.control}
                    name="image_url"
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Изображение</FormLabel>
                            <FormControl>
                                <Select onValueChange={handleImageSelect} value={selectedImage || ''}>
                                    <SelectTrigger className="w-[180px]">
                                        <SelectValue>
                                            {selectedImageTitle || "Выберите изображение"} {/* Показываем выбранное название */}
                                        </SelectValue>
                                    </SelectTrigger>
                                    <SelectContent>
                                        {imageOptions.map((option) => (
                                            <SelectItem key={option.url} value={option.url}>
                                                {option.title}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                {/* Отображение выбранного изображения */}
                {selectedImage && (
                    <div className="mt-4 flex">
                        <img src={selectedImage} alt="Выбранное изображение" className="w-48 h-auto border" />
                    </div>
                )}

                {/* Состояние загрузки */}
                {loading && <div>Загрузка изображений...</div>}

                {/* Кнопка отправки */}
                <div className="flex w-full">
                    <Button variant="default" type="submit" className="mt-4" disabled={isClear} >
                        Отправить
                    </Button>
                </div>
            </form>
        </FormProvider>
    );
};

export default NewStoryForm;
