import React, {useEffect, useState} from 'react';
import NewTaskForm, {FormData} from "@/pages/tasks/new-task/new-task-form";
import {useApi} from "@/pages/api-provider/api-context";
import FetchWithAuth from "@/pages/login/fetch-with-auth"; // Импортируйте ваш тип

interface EditTaskPageProps {
    task_id: number | null;
    onSaveChanges: () => void;
    closeModal: () => void;
}

const EditTaskPage: React.FC<EditTaskPageProps> = ({ task_id, closeModal, onSaveChanges }) => {
    const {apiUrl} = useApi();
    const [taskData, setTaskData] = useState<FormData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchTaskData = async () => {
            try {
                // Замените на ваш API вызов для получения данных задачи
                const response = await FetchWithAuth(`${apiUrl}/admin/get-task`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({
                        task_id: task_id,
                    }),
                });
                const data: FormData = await response.json();
                setTaskData(data);
            } catch (error) {
                console.error("Ошибка при загрузке данных задачи:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTaskData();
    }, [task_id, apiUrl]);

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    return (
        <NewTaskForm
            initialData={taskData}
            isEditMode={true}
            onSubmitSuccess={() => {
                // Логика после успешного редактирования
                closeModal();
                onSaveChanges();
            }}
        />

    );
};

export default EditTaskPage;
