import React, {useEffect, useState} from 'react';
import './CatPeek.css';


const CatPeek: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [leftPosition, setLeftPosition] = useState<number>(0);
    const timeoutRef = React.useRef<number | null>(null); // Ссылка для хранения ID таймера


    // Функция для установки случайной горизонтальной позиции
    const randomHorizontalPosition = () => {
        const randomLeft = Math.random() * 100; // Процентное значение
        setLeftPosition(randomLeft);
    };

    // const getRandomTime = () => {
    //     return Math.floor(Math.random() * (60000 - 10000 + 1)) + 10000; // От 10 секунд до 1 минуты
    // };
     // Функция для генерации случайного времени (от 1 до 15 минут)
    const getRandomTime = () => {
        return Math.floor(Math.random() * (900000 - 60000 + 1)) + 60000; // От 1 до 15 минут
    };

    useEffect(() => {
        const showCat = () => {
            randomHorizontalPosition(); // Устанавливаем случайную горизонтальную позицию
            setIsVisible(true); // Показываем котика

            // Скрываем котика через 10 секунд (10000 мс)
            timeoutRef.current = window.setTimeout(() => {
                setIsVisible(false);
            }, 10000); // 10000 мс = 10 секунд
        };

        const scheduleNextShow = () => {
            const randomDelay = getRandomTime(); // Получаем случайное время
            timeoutRef.current = window.setTimeout(() => {
                showCat();
                scheduleNextShow(); // Запланировать следующее появление
            }, randomDelay);
        };

        scheduleNextShow(); // Запускаем первую анимацию

        return () => {
            if (timeoutRef.current !== null) {
                clearTimeout(timeoutRef.current); // Очищаем таймер
            }
        };
    }, []);

    return (
        <div className="cat-container">
            {isVisible && (
                <img
                    src="/cat.png" // Путь к изображению кота
                    alt="Cat"
                    className="cat bottom" // Устанавливаем только класс для нижней позиции
                    style={{
                        left: `${leftPosition}%`, // Устанавливаем случайную позицию
                    }}
                />
            )}
        </div>
    );
};

export default CatPeek;
