import React, {useState} from 'react';

import {ResizableHandle, ResizablePanel, ResizablePanelGroup,} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import SessionsPage from "@/pages/statistics/sessions/sessions-page";
import TasksCountPage from "@/pages/statistics/tasks-count/tasks-count";
import StatMenu from "@/pages/statistics/stat-menu";
import UsersSpinStatPage from "@/pages/statistics/fortune/user-spin-stat/users-spin-stat-page";
import AppStatPage from "@/pages/statistics/app/app-stat-page";

// Основной компонент
const StatisticsMainPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");

    // Функция для рендеринга выбранного компонента на главной странице
    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "tasks-count":
                return <TasksCountPage />;
            case "sessions":
                return <SessionsPage/>;
            case "users-spin-stats":
                return <UsersSpinStatPage />
            case "app-stat":
                return <AppStatPage />;
            default:
                return <AppStatPage />;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <StatMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default StatisticsMainPage;
