import React from "react";
import {Contact, SmilePlus, Users} from "lucide-react";
import {Command, CommandGroup, CommandItem, CommandList, CommandSeparator,} from "@/components/ui/command";
import {Card} from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function UsersMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[600px] py-1">
                <CommandList>
                    <CommandGroup  className="text-lg font-bold" heading="USERS" />
                    <CommandSeparator/>
                    <CommandGroup heading="Actions">
                        <CommandItem onSelect={() => onSelectCommand("send-money")}>
                            <SmilePlus className="mr-2" />
                            <span>Send money</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator/>
                    <CommandGroup heading="Information">
                        <CommandItem onSelect={() => onSelectCommand("all-users")}>
                            <Users className="mr-2" />
                            <span>All users</span>
                        </CommandItem>
                        <CommandItem onSelect={() => onSelectCommand("find-user")}>
                            <Users className="mr-2" />
                            <span>Find user</span>
                        </CommandItem>
                        <CommandItem onSelect={() => onSelectCommand("referrals")}>
                            <Contact size={20} className="mr-2" />
                            <span>Referrals</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandSeparator />
                </CommandList>
            </Command>
        </Card>
    );
}
