"use client";

import {
    NavigationMenu,
    NavigationMenuItem,
    NavigationMenuLink,
    NavigationMenuList,
} from "@/components/ui/navigation-menu";
import {ModeToggle} from "./mode-toggle";
import LogoutButton from "@/pages/login/logout";
import {Button} from "@/components/ui/button";
import Header from "@/pages/api-provider/header"; // Импортируем компонент LogoutButton

export default function Nav() {
    return (
        <div className="py-3 flex flex-row justify-between w-full">
            <NavigationMenu>
                <NavigationMenuList>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/"><Button variant="outline">Home</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/users"><Button variant="outline">Users</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/tasks"><Button variant="outline" >Tasks</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/stories"><Button variant="outline">Stories</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/promocodes"><Button variant="outline">Promocodes</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/fortune"><Button variant="outline">Fortune Wheel</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/stat"><Button variant="outline">Statistics</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                    <NavigationMenuItem>
                        <NavigationMenuLink href="/settings"><Button variant="outline">Settings</Button></NavigationMenuLink>
                    </NavigationMenuItem>
                </NavigationMenuList>
            </NavigationMenu>
            <div className="flex flex-row justify-end w-full">
                <div className="pr-1">
                    <Header/>
                </div>
                <div className="pr-1">
                    <ModeToggle/>
                </div>
                <div>
                    <LogoutButton/>
                </div>
            </div>

        </div>
    );
}
