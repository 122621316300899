import React, { useEffect } from 'react';

const ScrollDisabler: React.FC = () => {
    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            const target = event.target as HTMLInputElement;
            if (target.type === "number" && document.activeElement === target) {
                event.preventDefault();
            }
        };

        window.addEventListener("wheel", handleWheel, { passive: false });

        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, []);

    return null;
};

export default ScrollDisabler;
