import {useEffect} from "react";

function App() {

    return (
        <div className="w-full items-center justify-center text-5xl text-center">
           <p>Welcome to Musgard Bot God's pages</p>
        </div>
    );
}

export default App;
