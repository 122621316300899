import {SetupTonBank} from "@/pages/settings/ton/setup-bank/setup-bank-form";

export function SetupTonBankPage(): JSX.Element {


    return (
        <div>
            <SetupTonBank />
        </div>
    )
}