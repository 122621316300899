import React, {useEffect, useState} from "react";
import {Navigate} from "react-router-dom";
import {useApi} from "@/pages/api-provider/api-context";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

// Интерфейс для данных ответа

// Функция проверки авторизации
const checkAuthorization = async (url: string): Promise<boolean> => {
  try {
    const response = await FetchWithAuth(url, {
      method: "GET",
      credentials: "include", // Включаем куки
    });
    // Проверка статуса ответа
    if (response.ok) {

      // Проверяем наличие сообщения об успешной авторизации
      return response.status === 200; // Убедитесь, что сервер возвращает нужное сообщение
    } else if (response.status === 500) {
      return false; // Пользователь не авторизован
    } else {
      console.error("Unexpected response status:", response.status);
      return false; // Ошибка, считаем не авторизованным
    }
  } catch (error) {
    console.error("Ошибка проверки авторизации:", error);
    return false; // В случае ошибки считаем не авторизованным
  }
};

// Компонент для защиты маршрута
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null); // null означает, что проверка ещё не завершена
  const {apiUrl} = useApi();

  useEffect(() => {
    const verifyAuth = async () => {
      const isAuth = await checkAuthorization(`${apiUrl}/admin/get-tasks`); // Убедитесь, что это правильный URL для проверки авторизации
      setIsAuthenticated(isAuth);
    };

    verifyAuth();
  }, [apiUrl]);

  // Если проверка ещё идёт, можно показать загрузку или пустой элемент
  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  // Если не авторизован — редирект на страницу логина
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  
  // Если авторизован — рендерим дочерние элементы (защищённый компонент)
  return children;
};

export default ProtectedRoute;
