import React from 'react';
import {useApi} from '../api-provider/api-context'; // Импортируем хук для работы с контекстом
import {Button} from "@/components/ui/button";
import {LogOut} from "lucide-react";
import {useNavigate} from "react-router-dom";

const LogoutButton: React.FC = () => {
    const { apiUrl } = useApi(); // Получаем apiUrl из контекста
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            const response = await fetch(`${apiUrl}/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include', // Если необходимо отправить куки
            });

            if (!response.ok) {
                throw new Error(`Ошибка при выходе: ${response.status}`);
            }


            navigate("/login")
            // Здесь можно выполнить дополнительные действия, например, перенаправление на страницу входа
        } catch (error) {
            console.error('Ошибка при выходе:', error);
        }
    };

    return (
        <Button variant="outline" size="icon" onClick={handleLogout}  className="p-1 pb-0 pt-0"><LogOut size={20}/></Button>
    );
};

export default LogoutButton;
