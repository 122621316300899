import React from 'react';
import {useApi} from './api-context';
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {Alert, AlertDescription, AlertTitle} from "@/components/ui/alert";
import {Terminal} from 'lucide-react';

export const PROD_URL = process.env.REACT_APP_API_URL_PROD || 'https://artdeepdown.online';
export const DEV_URL = process.env.REACT_APP_API_URL_DEV || 'https://artdeepdown.online';
export const TEST_URL = process.env.REACT_APP_API_URL_TEST || 'https://artdeepdown.online';

const EnvSelector: React.FC = () => {
    const { apiUrl, setApiUrl } = useApi();


    const handleEnvChange = (newEnv: string) => {
        if (newEnv !== apiUrl) {
            setApiUrl(newEnv);
        }
    };

    const getBorderColor = () => {
        switch (apiUrl) {
            case PROD_URL:
                return "border-red-500";
            case DEV_URL:
                return "border-blue-500";
            case TEST_URL:
                return "border-green-500";
            default:
                return "border-yellow-300";
        }
    };

    return (
        <div className="w-full">
            <Alert className={`flex flex-col items-start space-y-4 p-4 rounded-lg ${getBorderColor()}`}>
                <div className="flex items-start space-x-2">
                    <Terminal className="h-5 w-5 flex-shrink-0 text-yellow-500" />
                    <div>
                        <AlertTitle className="font-bold">Внимание! Выберите стенд!</AlertTitle>
                    </div>
                </div>

                <div className="w-full">
                    <Select onValueChange={handleEnvChange} value={apiUrl}>
                        <SelectTrigger className="w-full border rounded-lg p-2">
                            <SelectValue placeholder="Выберите стенд" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={PROD_URL}>Production</SelectItem>
                            <SelectItem value={DEV_URL}>Stage</SelectItem>
                            <SelectItem value={TEST_URL}>Development</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </Alert>
        </div>
    );
};

export default EnvSelector;
