import React, { useEffect, useState } from 'react';
import { useApi } from "@/pages/api-provider/api-context";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import NewGoodForm, { FormData } from "@/pages/fortune/shop/goods/new-good-form";

interface EditTaskPageProps {
    good_id: number | null;
    onSaveChanges: () => void;
    closeModal: () => void;
}

const EditGoodPage: React.FC<EditTaskPageProps> = ({ good_id, closeModal, onSaveChanges }) => {
    const { apiUrl } = useApi();
    const [goodData, setGoodData] = useState<FormData | null>(null); // null для начального значения
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchGoodData = async () => {
            if (!good_id) {
                console.error("good_id is null");
                setIsLoading(false);
                return;
            }

            try {
                const response = await FetchWithAuth(`${apiUrl}/admin/get-good`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify({ good_id: Number(good_id) }),
                });

                if (!response.ok) {
                    throw new Error("Failed to fetch good data");
                }

                const data: FormData = await response.json();

                setGoodData(data);
            } catch (error) {
                console.error("Ошибка при загрузке данных товара:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchGoodData();
    }, [good_id, apiUrl]);

    if (isLoading) {
        return <div>Загрузка...</div>;
    }

    if (!goodData) {
        return <div>Ошибка: данные товара не найдены.</div>; // Предотвращаем передачу undefined в NewGoodForm
    }

    return (

        <NewGoodForm
            initialData={goodData}
            isEditMode={true}
            onSubmitSuccess={() => {
                closeModal();
                onSaveChanges();
            }}
            update={() => closeModal}
        />
    );
};

export default EditGoodPage;
