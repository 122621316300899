// api-context.tsx
import {createContext, ReactNode, useContext, useEffect, useState} from 'react';

// Определяем интерфейс для значения контекста
interface ApiContextType {
    apiUrl: string;
    setApiUrl: React.Dispatch<React.SetStateAction<string>>;
}

// Создаем контекст с начальным значением null
const ApiContext = createContext<ApiContextType | null>(null);

export const ApiProvider = ({ children }: { children: ReactNode }) => {
    // Сохраняем начальное значение URL из localStorage, если оно существует
    const [apiUrl, setApiUrl] = useState<string>(() => {
        return localStorage.getItem('apiUrl') || "https://artdeepdown.online"; // Укажите ваш URL по умолчанию
    });

    // Сохраняем URL в localStorage при его изменении
    useEffect(() => {
        localStorage.setItem('apiUrl', apiUrl);
    }, [apiUrl]);

    return (
        <ApiContext.Provider value={{ apiUrl, setApiUrl }}>
            {children}
        </ApiContext.Provider>
    );
};

// Хук для использования контекста
export const useApi = () => {
    const context = useContext(ApiContext);
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider');
    }
    return context;
};
