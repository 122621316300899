import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { useApi } from "@/pages/api-provider/api-context";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Form, FormField, FormItem, FormLabel, FormControl, FormMessage } from "@/components/ui/form";

interface Price {
    asset: string;
    amount: number;
}

export interface FormData {
    id: number;
    amount: number;
    type: string;
    price: Price;
    maxAvailable: number;
}

interface GoodFormProps {
    initialData?: FormData | null;
    isEditMode?: boolean;
    update: () => void;
    onSubmitSuccess: () => void;
}

const NewGoodForm: React.FC<GoodFormProps> = ({
                                                  initialData,
                                                  isEditMode = false,
                                                  onSubmitSuccess,
                                                  update
                                              }) => {
    const { apiUrl } = useApi();

    const form = useForm<FormData>({
        defaultValues: initialData || {
            id: 0,
            amount: 0,
            type: "diamond",
            price: { asset: "star", amount: 1 },
            maxAvailable: 0,
        },
    });

    const { control, setValue, handleSubmit, reset, watch } = form;
    const watchPrice = watch("price");

    const handlePriceChange = (asset: string) => {
        const maxAvailable = asset === "star" ? 0 : 3;
        setValue("maxAvailable", maxAvailable);
        setValue("price", { ...watchPrice, asset });
    };

    const onSubmit = async (data: FormData) => {
        try {
            const apiUrlToUse = isEditMode
                ? `${apiUrl}/admin/update-good`
                : `${apiUrl}/admin/new-good`;

            const payload = {
                ...data,
                id: Number(data.id),
                amount: Number(data.amount),
                maxAvailable: Number(data.maxAvailable),
            };




            const response = await FetchWithAuth(apiUrlToUse, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                credentials: "include",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Ошибка при отправке данных");
            }

            toast.success(isEditMode ? "Good updated successfully!" : "New good created!");
            onSubmitSuccess();
            reset();
        } catch (error) {
            console.error(error);
            toast.error("Произошла ошибка при отправке формы.");
        } finally {
            if (!isEditMode) update();
        }

    };

    return (
        <Form {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isEditMode && (
                    <FormItem>
                        <FormLabel>Edit Item {form.getValues("id")}</FormLabel>
                    </FormItem>
                )}

                <FormField
                    name="amount"
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Amount</FormLabel>
                            <FormControl>
                                <Input {...field} type="number" placeholder="Enter amount" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    name="type"
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Good Type</FormLabel>
                            <FormControl>
                                <Select value={field.value} onValueChange={field.onChange}>
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Type" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value="diamond">diamond</SelectItem>
                                            <SelectItem value="fortune">fortune</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    name="price.amount"
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Price Amount</FormLabel>
                            <FormControl>
                                <Input
                                    {...field}
                                    type="number"
                                    placeholder="Enter price amount"
                                    value={field.value || 1}
                                    onChange={(e) =>
                                        setValue("price", {
                                            ...watchPrice,
                                            amount: Number(e.target.value),
                                        })
                                    }
                                />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    name="price.asset"
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Price Asset</FormLabel>
                            <FormControl>
                                <Select
                                    value={field.value}
                                    onValueChange={(value) => {
                                        field.onChange(value);
                                        handlePriceChange(value);
                                    }}
                                >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Asset" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectGroup>
                                            <SelectItem value="star">star</SelectItem>
                                            <SelectItem value="coin">coin</SelectItem>
                                        </SelectGroup>
                                    </SelectContent>
                                </Select>
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <FormField
                    name="maxAvailable"
                    control={control}
                    render={({ field }) => (
                        <FormItem>
                            <FormLabel>Max Available</FormLabel>
                            <FormControl>
                                <Input {...field} type="number" placeholder="Enter max available" />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    )}
                />

                <Button
                    type="submit"
                    className={`w-full text-white ${
                        isEditMode ? "bg-blue-500 hover:bg-blue-600" : "bg-green-500 hover:bg-green-600"
                    }`}
                >
                    {isEditMode ? "SAVE CHANGES" : "CREATE"}
                </Button>
            </form>
        </Form>
    );
};

export default NewGoodForm;
