import React from "react";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table";
import {Button} from "@/components/ui/button";

// Тип данных для таблицы
export type Good = {
    id: number; // uint     json:"id"
    amount: number; // int      json:"amount"
    type: string; // json:"type"
    price: {
        amount: number;
        asset: string;
    }; // json:"price"
    paymentLink: string; // json:"payment_link"
    available: number; // json:"available"
    maxAvailable: number; // json:"max_available"
};

interface TableComponentProps {
    data: Good[];
    onDelete: (id: number) => void;
    onEdit: (id: number) => void;
}

const AllGoodsTable: React.FC<TableComponentProps> = ({ data, onDelete, onEdit}) => {
    // Сортировка по id от большего к меньшему
    const sortedData = [...data].sort((a, b) => b.id - a.id);

    return (
        <Table>
            <TableHeader>
                <TableRow>
                    <TableHead className="text-center">ID</TableHead>
                    <TableHead className="text-center">Amount</TableHead>
                    <TableHead className="text-center">Type</TableHead>
                    <TableHead className="text-center">Price (Amount)</TableHead>
                    <TableHead className="text-center">Price (Asset)</TableHead>
                    <TableHead className="text-center">Payment Link</TableHead>
                    <TableHead className="text-center">Available</TableHead>
                    <TableHead className="text-center">Actions</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {sortedData.map((good) => (
                    <TableRow key={good.id}>
                        <TableCell className="text-center">{good.id}</TableCell>
                        <TableCell className="text-center">{good.amount}</TableCell>
                        <TableCell className="text-center">{good.type}</TableCell>
                        <TableCell className="text-center">{good.price.amount}</TableCell>
                        <TableCell className="text-center">{good.price.asset}</TableCell>
                        <TableCell className="text-center">
                            <a href={good.paymentLink} target="_blank" rel="noopener noreferrer">
                                {good.paymentLink}
                            </a>
                        </TableCell>
                        <TableCell className="text-center">{good.available}</TableCell>
                        <TableCell className="text-center">{good.maxAvailable}</TableCell>
                        <TableCell className="text-center flex flex-row size-fit">
                            <Button variant="secondary" onClick={() => onEdit(good.id)}>
                                Edit
                            </Button>
                            <Button variant="destructive" onClick={() => onDelete(good.id)}>
                                Delete
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

export default AllGoodsTable;
