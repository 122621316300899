import {LockScreenSwitchForm} from "@/pages/settings/lock-screen/toggle-lock-screen-form";

export function LockScreenPage(): JSX.Element {


    return (
        <div>
            <LockScreenSwitchForm />
        </div>
    )
}