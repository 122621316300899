import React from "react";
import {
    Users,
    Contact,
    SmilePlus, ClipboardList, MonitorCheck
} from "lucide-react";
import {
    Command,
    CommandGroup,
    CommandItem,
    CommandList,
    CommandSeparator,
} from "@/components/ui/command";
import { Card } from "@/components/ui/card";

interface MenuProps {
    onSelectCommand: (command: string) => void; // Пропс с типом функции
}

export default function StatMenu({ onSelectCommand }: MenuProps) {
    return (
        <Card>
            <Command className="text-lg rounded-lg border shadow-md md:min-w-[450px] py-1">
                <CommandList>
                    <CommandGroup  className="text-lg font-bold" heading="Statistics" />
                    <CommandGroup heading="App">
                        <CommandItem onSelect={() => onSelectCommand("app-stat")}>
                            <MonitorCheck size={20} className="mr-2"/>
                            <span>Statistics</span>
                        </CommandItem>
                    </CommandGroup>
                    <CommandGroup heading="Tasks">
                        <CommandItem onSelect={() => onSelectCommand("tasks-count")}>
                            <ClipboardList size={20} className="mr-2"/>
                            <span>Tasks</span>
                        </CommandItem>
                        <CommandSeparator/>
                        <CommandGroup heading="Users">
                        <CommandItem onSelect={() => onSelectCommand("sessions")}>
                            <MonitorCheck size={20} className="mr-2"/>
                            <span>Sessions</span>
                        </CommandItem>
                        </CommandGroup>
                        <CommandGroup heading="Fortune">
                            <CommandItem onSelect={() => onSelectCommand("users-spin-stats")}>
                                <MonitorCheck size={20} className="mr-2"/>
                                <span>users stats</span>
                            </CommandItem>
                        </CommandGroup>
                    </CommandGroup>
                </CommandList>
            </Command>
        </Card>
    );
}
