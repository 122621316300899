"use client";

import { useForm } from "react-hook-form";
import { Button } from "@/components/ui/button";
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormMessage,
} from "@/components/ui/form";
import { toast } from "sonner";
import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import React, { useEffect, useState } from "react";
import { Input } from "@/components/ui/input";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import { useApi } from "@/pages/api-provider/api-context";

interface TonBank {
    bank: number;
}

export function SetupTonBank() {
    const [open, setOpen] = useState(false);
    const [bankValue, setBank] = useState(0); // Внешнее состояние для банка
    const [loading, setLoading] = useState(true);
    const { apiUrl } = useApi();

    // Форма с использованием React Hook Form
    const form = useForm<TonBank>({
        defaultValues: {
            bank: bankValue, // Значение банка по умолчанию
        },
    });

    // Получение данных с сервера при загрузке компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await FetchWithAuth(`${apiUrl}/admin/ton-bank`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });
                const data = await response.json();
                setBank(data.bank); // Сохраняем только значение поля bank
                form.setValue("bank", data.bank); // Устанавливаем значение в форму
            } catch (err) {
                console.error(err);
                toast.warning("Oops!", {
                    description: `Something went wrong, check the console! ${err}`,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl, form]);

    // Обработка отправки формы
    const handleSubmit = async () => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/ton-bank`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ bank: form.getValues("bank") }), // Отправляем значение банка
                credentials: "include",
            });
            if (!response.ok) {
                throw new Error("Ошибка при отправке данных");
            }

            toast.success("Successfully Setup TonBank", {
                description: `Your bank now is: ${form.getValues("bank")}`,
            });
            setBank(form.getValues("bank")); // Обновляем внешнее состояние
        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, check the console! ${error}`,
            });
        }
    };

    const openSubmitDialog = () => {
        setOpen(true);
    };

    if (loading) {
        return <div>Getting data from server...</div>;
    }

    return (
        <Form {...form}>
            <form onSubmit={form.handleSubmit(openSubmitDialog)} className="w-full justify-between">
                <div>
                    <div className="flex items-center justify-between pb-2">
                        <label>TON in bank: {bankValue}</label>
                    </div>

                    <div>Setup new TON value</div>
                    <div className="grid grid-cols-4 gap-4">
                        <FormField
                            control={form.control}
                            name="bank"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <Input
                                            type="number"
                                            placeholder="Enter new value"
                                            {...field} // Привязываем значение формы через field
                                            onChange={(e) => {
                                                const newValue = parseFloat(e.target.value) || 0;
                                                field.onChange(newValue); // Обновляем значение в форме
                                                setBank(newValue); // Обновляем внешнее состояние
                                            }}

                                        />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <Button type="submit">Submit</Button>
                    </div>
                </div>

                {/* Диалоговое окно подтверждения */}
                <AlertDialog open={open} onOpenChange={setOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel onClick={() => setOpen(false)}>
                                Cancel
                            </AlertDialogCancel>
                            <AlertDialogAction onClick={handleSubmit}>
                                Confirm
                            </AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </form>
        </Form>
    );
}
