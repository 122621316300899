import React from 'react';
import {useApi} from './api-context';

const Header: React.FC = () => {
    const { apiUrl } = useApi(); // Получаем текущее значение URL

    const PROD_URL = process.env.REACT_APP_API_URL_PROD || 'prod';
    const DEV_URL = process.env.REACT_APP_API_URL_DEV || 'dev';
    const TEST_URL = process.env.REACT_APP_API_URL_TEST || 'test';

    // Функция для динамического изменения цвета фона в зависимости от выбранного стенда
    const getBackgroundColor = () => {
        switch (apiUrl) {
            case PROD_URL:
                return "bg-red-500";
            case DEV_URL:
                return "bg-blue-500";
            case TEST_URL:
                return "bg-green-500";
            default:
                return "bg-yellow-300";
        }
    };

    // Функция для получения отображаемого названия стенда
    const getDisplayName = () => {
        switch (apiUrl) {
            case DEV_URL:
                return "STAGE";
            case PROD_URL:
                return "PRODUCTION";
            case TEST_URL:
                return "DEVELOPMENT";
            default:
                return "unknown";
        }
    };

    return (
        <header className={`${getBackgroundColor()}`}>
            <h1 className="text-center text-3xl font-bold rounded-b-sm p-1 pb-0 pt-0">
                {getDisplayName()}
            </h1>
        </header>
    );
};

export default Header;
