import {useForm} from "react-hook-form";
import {Button} from "@/components/ui/button";
import {Form, FormControl, FormField, FormItem, FormLabel, FormMessage,} from "@/components/ui/form";
import {Card, CardContent, CardFooter, CardHeader} from "@/components/ui/card";
import {Input} from "@/components/ui/input";
import {useApi} from "../../api-provider/api-context";
import React, {useEffect, useState} from "react";
import {toast} from "sonner";
import {Checkbox} from "@/components/ui/checkbox";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import {X} from "lucide-react";

// Пропсы для компонента
interface TaskFormProps {
    initialData?: FormData | null; // Теперь принимает null
    isEditMode?: boolean;   // определяет, редактируем или создаем
    onSubmitSuccess: () => void; // callback при успешной отправке
}

export interface FormData {
    id: number,
    link_url: string;
    partner: boolean;
    icon: string | null;
    pinned: boolean;
    platform: string;
    reward: number;
    reward_increment: number;
    step_total: number;
    texts: {
        en: {
            button: string;
            description: string;
            link: string;
            title: string;
        };
        ru: {
            button: string;
            description: string;
            link: string;
            title: string;
        };
    };
    type: string;
    active: boolean;
    expires_at: number;
}


interface Icon {
    title: string; // Название изображения
    url: string;   // URL изображения
}

const getPartnerIcons = async (apiUrl: string): Promise<Icon[]> => {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-partner-icons`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });

        if (!response.ok) {

            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const resp = await response.json();

        return resp.images || []; // Убедитесь, что вы возвращаете массив
    } catch (error) {
        console.error(error);
        toast.warning("Oops!", {
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
};

type TextFieldKeys = keyof FormData['texts']['en'];

const NewTaskForm: React.FC<TaskFormProps> = ({
                                                  initialData,
                                                  isEditMode = false,
                                                  onSubmitSuccess,
                                              }) => {
    const { apiUrl } = useApi();
    const [icons, setImages] = useState<Icon[]>([]);
    const form = useForm<FormData>({
        defaultValues: initialData || {
            id: 0,
            link_url: "",
            partner: false,
            icon: null,
            pinned: false,
            platform: "",
            reward: 0,
            reward_increment: 0,
            step_total: 1,
            texts: {
                en: {
                    button: "",
                    description: "",
                    link: "",
                    title: "",
                },
                ru: {
                    button: "",
                    description: "",
                    link: "",
                    title: "",
                },
            },
            type: "subscribe",
            active: true,
            expires_at: 7,
        },
    });

    const [charCounts, setCharCounts] = useState({
        en: { button: 0, description: 0, link: 0, title: 0 },
        ru: { button: 0, description: 0, link: 0, title: 0 },
    });

    useEffect(() => {

        const fetchData = async () => {
            try {

                const icons = await getPartnerIcons(apiUrl)
                setImages(icons)

                // Сохраняем только значение поля bank
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        // Инициализируем счётчики при редактировании
        if (isEditMode && initialData) {
            form.setValue("expires_at", 7);

            setCharCounts({
                en: {
                    button: initialData.texts.en.button.length,
                    description: initialData.texts.en.description.length,
                    link: initialData.texts.en.link.length,
                    title: initialData.texts.en.title.length,
                },
                ru: {
                    button: initialData.texts.ru.button.length,
                    description: initialData.texts.ru.description.length,
                    link: initialData.texts.ru.link.length,
                    title: initialData.texts.ru.title.length,
                },
            });
        }
    }, [initialData, isEditMode]);

    const maxLength = 999;

    const handleChange = (
        lang: 'en' | 'ru',
        fieldName: TextFieldKeys,
        onChange: (...args: any) => void,
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newValue = event.target.value;

        if (newValue.length <= maxLength) {
            setCharCounts((prevCounts) => ({
                ...prevCounts,
                [lang]: {
                    ...prevCounts[lang],
                    [fieldName]: newValue.length,
                },
            }));

            onChange(event);
        }
    };

    const onSubmit = async (values: FormData) => {
        const payload = {
            id: values.id,// По умолчанию ID, можно изменить при необходимости
            link_url: values.link_url,
            partner: values.partner,
            pinned: values.pinned,
            platform: values.platform,
            icon: values.icon,
            reward: Number(values.reward), // Преобразование в число
            reward_increment: Number(values.reward_increment), // Преобразование в число
            step_total: Number(values.step_total), // Преобразование в число
            texts: {
                en: {
                    button: values.texts.en.button,
                    description: values.texts.en.description,
                    link: values.texts.en.link,
                    title: values.texts.en.title,
                },
                ru: {
                    button: values.texts.ru.button,
                    description: values.texts.ru.description,
                    link: values.texts.ru.link,
                    title: values.texts.ru.title,
                },
            },
            type: values.type,
            active: values.active,
            expires_at: Number(values.expires_at)
        };



        try {
            const apiUrlToUse = isEditMode
                ? `${apiUrl}/admin/update-task`
                : `${apiUrl}/admin/new-task`;

            const response = await FetchWithAuth(apiUrlToUse, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                throw new Error("Ошибка при отправке данных");
            }

            toast.success(isEditMode ? "Task updated successfully!" : "Task created successfully!");
            onSubmitSuccess();
        } catch (error) {
            console.error(error);
            toast.error("Произошла ошибка при отправке формы.");
        }
    };

    const typeValue = form.watch("type")
    const isDaily = typeValue === "daily";
    const isSubscribe = typeValue === "subscribe"


    const isPartner = form.watch("partner")

    const onClear = () => {
        form.setValue("icon", null);
    }





    return (
        <div className="flex p-4">
            <div className="w-full">
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
                        <Card>
                            <CardHeader className="text-lg font-semibold">
                                {isEditMode ? `Edit Task ${form.getValues("id")}` : "Create New Task"}
                            </CardHeader>
                            <CardContent>
                                <div className={`grid gap-4 ${isSubscribe ? 'grid-cols-4' : 'grid-cols-2'}`}>
                                            {/* RU Section */}
                                            {isSubscribe && (
                                                <div className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                                    <h3 className="font-semibold mb-1 text-sm">Russian (RU)</h3>
                                                    <FormField
                                                        name="texts.ru.title"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormLabel className="text-xs">Title (RU)</FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        className="h-9"
                                                                        placeholder="Enter title"
                                                                        maxLength={maxLength} // Ограничение символов
                                                                        value={field.value}
                                                                        onChange={(event) => handleChange('ru', 'title', field.onChange, event)}
                                                                    />
                                                                </FormControl>
                                                                <div className="text-xs text-gray-500">
                                                                    Осталось символов: {maxLength - charCounts.ru.title}
                                                                </div>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        name="texts.ru.button"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormLabel className="text-xs">Button text
                                                                    (RU)</FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        className="h-9"
                                                                        placeholder="button text"
                                                                        maxLength={maxLength} // Ограничение символов
                                                                        value={field.value}
                                                                        onChange={(event) => handleChange('ru', 'button', field.onChange, event)}
                                                                    />
                                                                </FormControl>
                                                                <div className="text-xs text-gray-500">
                                                                    Осталось
                                                                    символов: {maxLength - charCounts.ru.button}
                                                                </div>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        name="texts.ru.description"
                                                        control={form.control}
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormLabel className="text-xs">Description
                                                                    (RU)</FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        className="h-9"
                                                                        placeholder="description text"
                                                                        maxLength={maxLength} // Ограничение символов
                                                                        value={field.value}
                                                                        onChange={(event) => handleChange('ru', 'description', field.onChange, event)}
                                                                    />
                                                                </FormControl>
                                                                <div className="text-xs text-gray-500">
                                                                    Осталось
                                                                    символов: {maxLength - charCounts.ru.description}
                                                                </div>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    <FormField
                                                        control={form.control}
                                                        name="texts.ru.link"
                                                        render={({field}) => (
                                                            <FormItem>
                                                                <FormLabel className="text-xs">Link</FormLabel>
                                                                <FormControl>
                                                                    <Input
                                                                        className="h-9"
                                                                        placeholder="Enter link"
                                                                        {...field}
                                                                    />
                                                                </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                </div>
                                            )}

                                            {/* EN Section */}
                                            {isSubscribe && (
                                            <div className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                                <h3 className="font-semibold mb-1 text-sm">English (EN)</h3>
                                                <FormField
                                                    name="texts.en.title"
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <FormItem>
                                                            <FormLabel className="text-xs">Title (EN)</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    className="h-9"
                                                                    placeholder="Enter title"
                                                                    maxLength={maxLength} // Ограничение символов
                                                                    value={field.value}
                                                                    onChange={(event) => handleChange('en', 'title', field.onChange, event)}
                                                                />
                                                            </FormControl>
                                                            <div className="text-xs text-gray-500">
                                                                Осталось символов: {maxLength - charCounts.en.title}
                                                            </div>
                                                            <FormMessage/>
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    name="texts.en.button"
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <FormItem>
                                                            <FormLabel className="text-xs">Button text (EN)</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    className="h-9"
                                                                    placeholder="button text"
                                                                    maxLength={maxLength} // Ограничение символов
                                                                    value={field.value}
                                                                    onChange={(event) => handleChange('en', 'button', field.onChange, event)}
                                                                />
                                                            </FormControl>
                                                            <div className="text-xs text-gray-500">
                                                                Осталось символов: {maxLength - charCounts.en.button}
                                                            </div>
                                                            <FormMessage/>
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    name="texts.en.description"
                                                    control={form.control}
                                                    render={({field}) => (
                                                        <FormItem>
                                                            <FormLabel className="text-xs">Description (EN)</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    className="h-9"
                                                                    placeholder="description text"
                                                                    maxLength={maxLength} // Ограничение символов
                                                                    value={field.value}
                                                                    onChange={(event) => handleChange('en', 'description', field.onChange, event)}
                                                                />
                                                            </FormControl>
                                                            <div className="text-xs text-gray-500">
                                                                Осталось
                                                                символов: {maxLength - charCounts.en.description}
                                                            </div>
                                                            <FormMessage/>
                                                        </FormItem>
                                                    )}
                                                />
                                                <FormField
                                                    control={form.control}
                                                    name="texts.en.link"
                                                    render={({field}) => (
                                                        <FormItem>
                                                            <FormLabel className="text-xs">Link</FormLabel>
                                                            <FormControl>
                                                                <Input
                                                                    className="h-9"
                                                                    placeholder="Enter link"
                                                                    {...field}
                                                                />
                                                            </FormControl>
                                                            <FormMessage/>
                                                        </FormItem>
                                                    )}
                                                />
                                            </div>
                                            )}


                                            {/* Links & Platform Block */}
                                    <div className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                        <h3 className="font-semibold mb-1 text-sm">Links & Platform</h3>
                                        <FormField
                                            control={form.control}
                                            name="link_url"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-xs">Link URL</FormLabel>
                                                    <FormControl>
                                                        <Input
                                                            className="h-9"
                                                            placeholder="Enter link URL"
                                                            {...field}
                                                        />
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="platform"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-xs">Platform</FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            onValueChange={(value) => field.onChange(value)}
                                                            defaultValue={field.value}
                                                        >
                                                            <SelectTrigger className="h-9">
                                                                <SelectValue placeholder="Select platform"/>
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectItem value="youtube">youtube</SelectItem>
                                                                <SelectItem value="twitter">twitter</SelectItem>
                                                                <SelectItem
                                                                    value="telegram">telegram</SelectItem>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />
                                        <FormField
                                            control={form.control}
                                            name="type"
                                            render={({field}) => (
                                                <FormItem>
                                                    <FormLabel className="text-xs">Type</FormLabel>
                                                    <FormControl>
                                                        <Select
                                                            onValueChange={(value) => field.onChange(value)}
                                                            defaultValue={field.value}
                                                        >
                                                            <SelectTrigger className="h-9">
                                                                <SelectValue placeholder="Select type"/>
                                                            </SelectTrigger>
                                                            <SelectContent>
                                                                <SelectItem value="none">daily - value
                                                                    (none)</SelectItem>
                                                                <SelectItem
                                                                    value="subscribe">subscribe</SelectItem>
                                                            </SelectContent>
                                                        </Select>
                                                    </FormControl>
                                                    <FormMessage/>
                                                </FormItem>
                                            )}
                                        />

                                        <div className="flex items-center mb-1">
                                            <FormField
                                                control={form.control}
                                                name="expires_at"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormLabel className="text-xs">Live time (days)</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="number"
                                                                className="h-9"
                                                                placeholder="Enter days amount"
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                        <FormMessage/>
                                                    </FormItem>
                                                )}
                                            />
                                        </div>
                                    </div>

                                    <div className="grid gap-4">
                                        {/* Reward Settings Block */}
                                        <div
                                            className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                            <h3 className="font-semibold mb-1 text-sm">Reward Settings</h3>
                                            <FormField
                                                control={form.control}
                                                name="reward"
                                                render={({field}) => (
                                                    <FormItem>
                                                        <FormLabel className="text-xs">Reward</FormLabel>
                                                        <FormControl>
                                                            <Input
                                                                type="number"
                                                                className="h-9"
                                                                placeholder="Enter reward"
                                                                {...field}
                                                            />
                                                        </FormControl>
                                                                <FormMessage/>
                                                            </FormItem>
                                                        )}
                                                    />
                                                    {isDaily && (
                                                        <div>
                                                            <FormField
                                                                control={form.control}
                                                                name="reward_increment"
                                                                render={({field}) => (
                                                                    <FormItem>
                                                                        <FormLabel className="text-xs">Reward
                                                                            Increment</FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                type="number"
                                                                                className="h-9"
                                                                                placeholder="Enter reward increment"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage/>
                                                                    </FormItem>
                                                                )}
                                                            />
                                                            <FormField
                                                                control={form.control}
                                                                name="step_total"
                                                                render={({field}) => (
                                                                    <FormItem>
                                                                        <FormLabel className="text-xs">Step
                                                                            Total</FormLabel>
                                                                        <FormControl>
                                                                            <Input
                                                                                type="number"
                                                                                className="h-9"
                                                                                placeholder="Enter total steps"
                                                                                {...field}
                                                                            />
                                                                        </FormControl>
                                                                        <FormMessage/>
                                                                    </FormItem>
                                                                )}
                                                            />
                                                        </div>
                                                    )}

                                                </div>

                                                {/* Options Block */}
                                                <div
                                                    className="flex flex-col space-y-2 p-4 border border-gray-300 rounded-lg">
                                                    <h3 className="font-semibold mb-1 text-sm">Options</h3>
                                                    <div className="flex items-center mb-1">
                                                        <FormField
                                                            control={form.control}
                                                            name="active"
                                                            render={({field}) => (
                                                                <FormItem className="flex items-center">
                                                                    <FormControl>
                                                                        <Checkbox
                                                                            checked={field.value}
                                                                            onCheckedChange={(checked: boolean) =>
                                                                                field.onChange(checked)
                                                                            }
                                                                            className="h-5 w-5 text-blue-600 border-gray-300"
                                                                        />
                                                                    </FormControl>
                                                                    <FormLabel
                                                                        className="ml-2 text-sm">Active</FormLabel>
                                                                    <FormMessage/>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex items-center  mb-1">
                                                        <FormField
                                                            control={form.control}
                                                            name="pinned"
                                                            render={({field}) => (
                                                                <FormItem className="flex items-center">
                                                                    <FormControl>
                                                                        <Checkbox
                                                                            checked={field.value}
                                                                            onCheckedChange={(checked: boolean) =>
                                                                                field.onChange(checked)
                                                                            }
                                                                            className="h-5 w-5 text-blue-600 border-gray-300"
                                                                        />
                                                                    </FormControl>
                                                                    <FormLabel
                                                                        className="ml-2 text-sm">Pinned</FormLabel>
                                                                    <FormMessage/>
                                                                </FormItem>
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="flex mb-1">
                                                        <div className="grid-cols-1">
                                                            <FormField
                                                                control={form.control}
                                                                name="partner"
                                                                render={({field}) => (
                                                                    <FormItem>
                                                                        <FormControl>
                                                                            <Checkbox
                                                                                checked={field.value}
                                                                                onCheckedChange={(checked: boolean) => {
                                                                                    // Изменяем значение чекбокса
                                                                                    field.onChange(checked);
                                                                                    // Если unchecked, сбрасываем icon
                                                                                    if (!checked) {
                                                                                        form.setValue("icon", null); // Убедитесь, что `form` доступен
                                                                                    }
                                                                                }}
                                                                                className="h-5 w-5 text-blue-600 border-gray-300"
                                                                            />
                                                                        </FormControl>
                                                                        <FormLabel
                                                                            className="ml-2 text-sm">Partner</FormLabel>
                                                                        <FormMessage/>
                                                                    </FormItem>
                                                                )}
                                                            />
                                                            {isPartner && (
                                                                <div className="pt-2  flex space-x-2 items-center">
                                                                    <FormField
                                                                        control={form.control}
                                                                        name="icon"
                                                                        render={({ field }) => (
                                                                            <FormItem>
                                                                                <FormControl>
                                                                                    <Select
                                                                                        value={field.value || ""} // Убедитесь, что значение сбрасывается корректно
                                                                                        onValueChange={(value: string | null) => field.onChange(value ?? "")} // Сбрасываем на пустую строку, если значение null
                                                                                    >
                                                                                        <SelectTrigger>
                                                                                            <SelectValue placeholder="Select icon" />
                                                                                        </SelectTrigger>
                                                                                        <SelectContent>
                                                                                            {icons.map((icon: Icon) => (
                                                                                                <SelectItem
                                                                                                    key={icon.title}
                                                                                                    value={icon.url.replace("minio", "s3")}
                                                                                                >
                                                                                                    <img
                                                                                                        src={icon.url.replace("minio", "s3")}
                                                                                                        width="50"
                                                                                                        height="50"
                                                                                                        alt={icon.title}
                                                                                                        style={{ display: "block", margin: "0 auto" }}
                                                                                                    />
                                                                                                </SelectItem>
                                                                                            ))}
                                                                                        </SelectContent>
                                                                                    </Select>
                                                                                </FormControl>
                                                                                <FormMessage />
                                                                            </FormItem>
                                                                        )}
                                                                    />
                                                                    <Button
                                                                        type="button"
                                                                        variant="outline"
                                                                        onClick={() => {
                                                                            form.setValue("icon", null); // Явно сбрасываем значение в форме на пустую строку
                                                                        }}
                                                                    >
                                                                        <X />
                                                                    </Button>

                                                                </div>
                                                            )}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                </div>
                            </CardContent>
                            <CardFooter>
                                <Button
                                    type="submit"
                                    className={`w-full text-white  ${!isEditMode ? 'bg-green-500 hover:bg-green-600' : 'bg-blue-500 hover:bg-blue-600'} `}
                                >
                                    {isEditMode ? "SAVE CHANGES" : "CREATE"}
                                </Button>
                            </CardFooter>
                        </Card>
                    </form>
                </Form>
            </div>
        </div>
    );
}

export default NewTaskForm;

