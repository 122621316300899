import React, {useEffect, useState} from "react";
import {useApi} from "@/pages/api-provider/api-context";
import {columns, TasksCount, TasksCountTable} from "./tasks-count-table";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";


async function getTasksCount(apiUrl: string): Promise<TasksCount[]> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-tasks-count`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        toast.warning("Oops!", {
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
}
const TasksCountPage: React.FC = () => {
    const { apiUrl } = useApi(); // Вызов хука внутри компонента
    const [data, setData] = useState<TasksCount[] | null>(null); // Состояние для данных
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибок
    // useEffect для загрузки данных при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getTasksCount(apiUrl); // Передаём apiUrl как параметр
                setData(data);
            } catch (err) {
                setError("Ошибка при загрузке данных");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]); // Добавляем apiUrl в зависимости, чтобы перезагружать данные при его изменении


    return (
        <div className="w-full grid grid-cols-2 gap-4 content-start items-center justify-center">
            <div className="col-span-2">
                    <div>
                        {loading ? (
                            <div>Загрузка...</div>
                        ) : error ? (
                            <div>{error}</div>
                        ) : data ? (
                            <TasksCountTable columns={columns} data={data} /> // Показать таблицу с данными
                        ) : (
                            <div>Нет данных для отображения</div>
                        )}
                    </div>
            </div>
        </div>
    );
}

export default TasksCountPage;