import React, {useEffect, useState} from 'react';
import {useApi} from '../../api-provider/api-context'; // Импортируем хук для работы с контекстом
import {columns, Session, SessionsTable} from './sessions-table';
import PaginationControls from "@/pages/pagination/pagination";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";

// Функция для получения сессий
async function getSessions(apiUrl: string, currentPage: number, currentOffset: number): Promise<Session[]> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-sessions?page=${currentPage}&offset=${currentOffset}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });

        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        toast.warning("Oops!",{
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
}

const SessionsPage: React.FC = () => {
    const { apiUrl } = useApi(); // Используем хук контекста
    const [data, setData] = useState<Session[] | null>(null); // Состояние для данных
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибок
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(50);

    const handlePageChange = async (newPage:number) => {
        setPage(newPage);
        try {
            const nextData = await getSessions(apiUrl,newPage, offset);
            setData(nextData);

        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
            setError("Ошибка при обновлении данных");
        }
    };

    const handleOffsetChange = async (newOffset: number) => {
        setOffset(newOffset);
        try {
            const nextData = await  getSessions(apiUrl,page,newOffset);
            setData(nextData);
        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
            setError("Ошибка при обновлении данных");
        }// Вызываем запрос с новым значением offset
    };

    // useEffect для загрузки данных при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);

            try {
                const sessions = await getSessions(apiUrl, page, offset); // Передаём apiUrl как параметр
                setData(sessions); // Устанавливаем полученные данные в состояние
            } catch (err) {
                setError("Ошибка при загрузке данных");
            } finally {
                setLoading(false); 
            }
        };

        fetchData();
    }, [apiUrl, offset, page]); // Запускаем эффект, когда изменяется apiUrl

    const renderSessionsTable = () => {
        if (loading) {
            return <div>Загрузка...</div>;
        }
        if (error) {
            return <div>{error}</div>;
        }
            return (
                <div>
                    <SessionsTable columns={columns} data={data ? data : []} />
                    <PaginationControls
                        onPageChange={handlePageChange}
                        onOffsetChange={handleOffsetChange}
                        page={page}
                        offset={offset}
                    />
                </div>
            );};
    return renderSessionsTable();
}

export default SessionsPage;