import ChangeRefLinkForm from "@/pages/settings/change-ref-link/change-ref-link-form";

export function ChangeRefLinkPage(): JSX.Element {


    return (
        <div>
            <ChangeRefLinkForm />
        </div>
    )
}