import React, {useState} from 'react';

import {ResizableHandle, ResizablePanel, ResizablePanelGroup,} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import AllTasksPage from "@/pages/tasks/all-tasks/tasks-page";
import NewTaskForm, {FormData} from "@/pages/tasks/new-task/new-task-form";
import TasksMenu from "@/pages/tasks/tasks-menu";
import {UploadPartnerIcon} from "@/pages/tasks/upload-partner-icon/upload-partner-icon";


// Основной компонент
const TasksMainPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");
    // Состояние для данных редактируемой задачи
    const [initialData, setInitialData] = useState<FormData | null>(null);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);

    // Функция для рендеринга выбранного компонента на главной странице
    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "new-partner-icon":
                return <UploadPartnerIcon />;
            case "all-tasks":
                return <AllTasksPage />;
            case "new-task":
                return (
                    <NewTaskForm
                        initialData={isEditMode ? initialData : undefined}
                        isEditMode={isEditMode}
                        onSubmitSuccess={() => {
                            setInitialData(null); // Сбросить данные после успешной отправки
                            setIsEditMode(false); // Вернуться в режим создания новой задачи
                            setSelectedCommand("all-tasks"); // Вернуться к отображению всех задач
                        }}
                    />
                );
            default:
                return <AllTasksPage />;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <TasksMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default TasksMainPage;
