import React, {useEffect, useState} from 'react';
import {Input} from "@/components/ui/input";
import {Button} from "@/components/ui/button";
import {Label} from "@/components/ui/label";
import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow} from "@/components/ui/table";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import {useApi} from "@/pages/api-provider/api-context";
import {toast} from "sonner";
import {Select, SelectContent, SelectItem, SelectTrigger, SelectValue} from "@/components/ui/select";
import {Card, CardContent, CardDescription, CardFooter, CardHeader} from "@/components/ui/card";
import {
    AlertDialog, AlertDialogAction, AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription, AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle
} from "@/components/ui/alert-dialog";

interface StepResponse {
    amount: number;
    asset: string;
    index: number;
    icon: string;
}

interface SpinConfig {
    Probabilities: number[];
    ProbabilitiesLucky: number[];
    Rewards: StepResponse[];
    TonDistribution: string;
    TonMinWin: number;
    TonMaxWin: number;
    TonMinBank: number;
    TonRisk: number;
    TonSpins: number;
    TonAccuracy: number;
}

interface Params {
    Mu: number;
    Sigma: number;
}

interface Icon {
    title: string; // Название изображения
    url: string;   // URL изображения
}

const getIcons = async (apiUrl: string): Promise<Icon[]> => {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-icons`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });

        if (!response.ok) {

            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const resp = await response.json();

        return resp.images || []; // Убедитесь, что вы возвращаете массив
    } catch (error) {
        console.error(error);
        toast.warning("Oops!", {
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
};

const SpinConfigForm: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const {apiUrl} = useApi();
    const [open, setOpen] = useState(false);
    const [bankValue, setBank] = useState(0);
    const [icons, setImages] = useState<Icon[]>([]);
    const defaultSpinConfig: SpinConfig = {
        Probabilities: [0],
        ProbabilitiesLucky: [0],
        Rewards: [{ index: 0, amount: 0, asset: 'coin', icon: `none`}],
        TonDistribution: 'normal',
        TonMinWin: 0,
        TonMaxWin: 0,
        TonMinBank: 0,
        TonSpins: 0,
        TonAccuracy: 0,
        TonRisk:0,
    };
    const [params, setParams] = useState<Params>({
        Mu: 1.6,
        Sigma: 0.7,
    });
    const [spinConfig, setSpinConfig] = useState<SpinConfig>(defaultSpinConfig);
    const [clicked, setClicked] = useState(false);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await FetchWithAuth(`${apiUrl}/admin/spinner`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });
                const data = await response.json();
                setSpinConfig(data)

                const bankResponse = await FetchWithAuth(`${apiUrl}/admin/ton-bank`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });
                const bank = await bankResponse.json();
                setBank(bank.bank);

                const icons = await getIcons(apiUrl)
                setImages(icons)


                 // Сохраняем только значение поля bank
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl]);


    interface Probabilities {
        probabilities: number[]
        lucky_probabilities: number[]
    }

    const getProbabilities = async () => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/get-probabilities`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(params)
            });
            const data: Probabilities = await response.json(); // Assuming the response is in JSON format


            // Создаём копию spinConfig и обновляем Probabilities
            const updatedSpinConfig = { ...spinConfig, Probabilities: data.probabilities || [], ProbabilitiesLucky: data.lucky_probabilities || []};
            setSpinConfig(updatedSpinConfig); // Устанавливаем новое состояние
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (clicked) {
            getProbabilities();
            setClicked(false);
        }
    }, [clicked]);

    const refreshAllData = async () => {
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/spinner`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
            });
            const data = await response.json(); // Assuming the response is in JSON format
            setSpinConfig(data)
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleParamsChange = (e: React.ChangeEvent<HTMLInputElement>, param: keyof Params) => {
        const { value } = e.target;

        setParams((prev) => ({
            ...prev,
            [param]: parseFloat(value),
        }));
    };

    const handleTableChange = (
        e: React.ChangeEvent<HTMLInputElement> | number | string,
        index: number,
        field: string
    ) => {
        if (!spinConfig) return;

        // Обработчик для изменения TonDistribution
        if (field === "TonDistribution" && typeof e === "string") {
            // Обновляем TonDistribution отдельно от Rewards
            setSpinConfig((prevState) => ({
                ...prevState,
                TonDistribution: e,
            }));
            return;
        }

        const updatedRewards = [...spinConfig.Rewards];
        const currentItem = updatedRewards[index];

        // Если изменение пришло в виде числа (например, для Select индекса)
        if (typeof e === "number") {
            const fieldToUpdate = "index";
            const newIndex = e;

            // Находим элемент с этим новым индексом
            const conflictingIndex = updatedRewards.findIndex(
                (reward) => reward.index === newIndex && reward !== currentItem
            );

            if (conflictingIndex !== -1) {
                // Если нашли конфликтующий элемент, меняем его индекс на старый
                updatedRewards[conflictingIndex] = {
                    ...updatedRewards[conflictingIndex],
                    [fieldToUpdate]: currentItem.index, // Устанавливаем старое значение для индекса
                };
            }

            // Обновляем текущий элемент с новым индексом
            updatedRewards[index] = {
                ...currentItem,
                [fieldToUpdate]: newIndex,
            };
        } else if (typeof e === "string") {
            // Для строковых значений в Rewards (например, "asset", "icon" и т.д.)
            updatedRewards[index] = {
                ...currentItem,
                [field]: e, // Обновляем только нужное поле в объекте Rewards
            };
        } else if (field === "amount" && typeof e === "string") {
            // Для поля "amount", обрабатываем ввод числового значения
            const value = parseFloat(e);
            updatedRewards[index] = {
                ...currentItem,
                [field]: isNaN(value) ? 0 : value, // Если значение не является числом, устанавливаем 0
            };
        } else {
            // Для других полей (например, input или другие числовые значения)
            const value = typeof e === "string" ? e : e.target.value;

            updatedRewards[index] = {
                ...currentItem,
                [field]: field === "amount" ? parseFloat(value) : value, // Преобразуем в float для поля amount, иначе сохраняем как есть
            };
        }

        // Обновляем состояние с изменениями
        setSpinConfig((prevState) => ({ ...prevState, Rewards: updatedRewards }));
    };








    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!spinConfig) return;

        const { name, value } = e.target;
        setSpinConfig(prevState => ({
            ...prevState!,
            [name]: Number(value) || value,
        }));
    };

    const handleSubmitTEST = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        console.log(spinConfig);
        setOpen(false);

    }
    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault(); // Prevent default button behavior
        try {
            const response = await FetchWithAuth(`${apiUrl}/admin/spinner`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                credentials: "include",
                body: JSON.stringify(spinConfig),
            });

            if (!response.ok) {
                throw new Error("Ошибка при отправке данных");
            }

            toast.success("Config updated!");
        } catch (error) {
            console.error(error);
            toast.error("Произошла ошибка при отправке формы.");
        }
        refreshAllData();
        setOpen(false)
    };




    if (loading || !spinConfig) {
        return <div>Getting data from server...</div>;
    }

    return (
        <div className="pl-0">
            <form>
                <Card>
                    <CardHeader>Probabilities setup</CardHeader>
                    <CardContent>
                        <div className="grid-cols-3">
                            <div className="flex items-center justify-between">
                                <div className="flex space-x-4">
                                    <div>
                                        <Label className="block text-sm font-medium">Mu</Label>
                                        <Input
                                            className="max-w-fit"
                                            type="number"
                                            name="mu"
                                            value={params?.Mu ?? 0} // Установка значения по умолчанию для mu
                                            onChange={(e) => handleParamsChange(e, "Mu")} // Универсальный обработчик
                                        />
                                        <CardDescription>Average</CardDescription>
                                    </div>
                                    <div>
                                        <Label className="block text-sm font-medium">Sigma</Label>
                                        <Input
                                            className="max-w-fit"
                                            type="number"
                                            name="sigma"
                                            value={params?.Sigma ?? 0} // Установка значения по умолчанию для sigma
                                            onChange={(e) => handleParamsChange(e, "Sigma")} // Универсальный обработчик
                                        />
                                        <CardDescription>Hello</CardDescription>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </CardContent>
                    <CardFooter>
                        <Button className="w-full bg-blue-500 hover:bg-blue-600" type="button" onClick={() => setClicked(true)}>Setup probabilities</Button>
                    </CardFooter>
                </Card>
            </form>
            <form>
                <div className="w-full items-center p-0 ">
                    <Card>
                        <CardHeader>Ton Settings</CardHeader>
                        <CardContent className="grid grid-cols-2 gap-y-2 gap-x-2">
                            <div
                                className="grid grid-cols-2 gap-4"> {/* Создаем две колонки с отступами между элементами */}
                                {/* 'TonSpins', 'TonAccuracy' deleted */}
                                {['TonDistribution', 'TonMinBank', 'TonMinWin', 'TonMaxWin',]
                                    .map((field) => (
                                        <div className="space-y-2" key={field}>
                                            <Label htmlFor={field}>{field}</Label>

                                            {field === 'TonDistribution' ? (
                                                <Select
                                                    value={spinConfig.TonDistribution} // Убедитесь, что это строка
                                                    onValueChange={(value: string) => handleTableChange(value, 0, 'TonDistribution')} // Строка передается в handleTableChange
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select distribution"/>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="lognormal">lognormal</SelectItem>
                                                        <SelectItem value="normal">normal</SelectItem>
                                                        <SelectItem value="uniform">uniform</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            ) : (
                                                <Input
                                                    type={field === 'TonDistribution' ? 'text' : 'number'}
                                                    id={field}
                                                    name={field}
                                                    value={
                                                        field === 'TonDistribution'
                                                            ? spinConfig.TonDistribution // Оставляем только для примера, так как выше используется Select
                                                            : spinConfig[field as keyof SpinConfig] !== undefined
                                                                ? spinConfig[field as keyof SpinConfig].toString()
                                                                : ''
                                                    }
                                                    onChange={handleInputChange}
                                                />
                                            )}


                                        </div>
                                    ))}

                                {/*<div className="pt-2 flex-row flex justify-center items-center w-full">*/}
                                {/*    <Label htmlFor="TonRisk">TonRisk</Label>*/}
                                {/*    <Input*/}
                                {/*        type="number"*/}
                                {/*        id="TonRisk"*/}
                                {/*        name="TonRisk"*/}
                                {/*        value={spinConfig.TonRisk}*/}
                                {/*        onChange={handleInputChange}*/}
                                {/*        className="mr-2"*/}
                                {/*    />*/}
                                {/*    <Button type="button" onClick={getTonRisk}>Get Ton Risk</Button>*/}
                                {/*</div>*/}
                            </div>

                            <div>
                                <CardContent className="flex justify-center w-full flex-col">
                                    <Card className="border-t border-yellow-500">
                                        <CardHeader>INFO</CardHeader>
                                        <CardContent>
                                            <div className="text-blue-500">TON BANK NOW: {bankValue}</div>
                                        </CardContent>
                                    </Card>
                                </CardContent>
                            </div>

                        </CardContent>


                    </Card>
                </div>
                <div className="grid grid-cols-1 gap-1 pb-4 ">
                    <Card>
                        <Table>
                            <TableHeader>
                                <CardHeader>Wheel settings</CardHeader>
                                <TableRow>
                                    <TableHead>Rewards</TableHead>
                                    <TableHead>Image</TableHead>
                                    <TableHead>Probabilities</TableHead>
                                    <TableHead>ProbabilitiesLucky</TableHead>

                                </TableRow>
                            </TableHeader>
                            <TableBody className="gap-0">
                                {spinConfig.Probabilities.map((prob, index) => (
                                    <TableRow key={index} className="gap-0">
                                        <TableCell
                                            className="flex gap-0 items-center"> {/* Убираем gap между ячейками */}
                                            <Select
                                                value={String(spinConfig.Rewards[index]?.index ?? 0)} // Преобразуем в строку для Select
                                                onValueChange={(value: string) => handleTableChange(Number(value), index, "Rewards")} // Преобразуем обратно в число
                                            >
                                                <SelectTrigger className="w-16">
                                                    <SelectValue placeholder="Select Index"/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {/* Генерация опций от 0 до 11 */}
                                                    {Array.from({length: 12}, (_, num) => (
                                                        <SelectItem key={num}
                                                                    value={String(num)}> {/* Передаем строковое значение в Select */}
                                                            {num}
                                                        </SelectItem>
                                                    ))}
                                                </SelectContent>
                                            </Select>
                                            <Input
                                                className="max-w-fit"
                                                type="number"
                                                name="amount"
                                                value={spinConfig.Rewards[index]?.amount ?? 0} // Установка значения по умолчанию
                                                onChange={(e) => handleTableChange(e, index, 'amount')} // Передаем 'amount' для обновления этого поля
                                            />
                                            <div> {/* Оборачиваем Select в div */}
                                                <Select
                                                    value={spinConfig.Rewards[index]?.asset}
                                                    onValueChange={(value) => handleTableChange(value, index, 'asset')}
                                                >
                                                    <SelectTrigger>
                                                        <SelectValue placeholder="Select currency"/>
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectItem value="coin">coin</SelectItem>
                                                        <SelectItem value="diamond">diamond</SelectItem>
                                                        <SelectItem value="ton">ton</SelectItem>
                                                    </SelectContent>
                                                </Select>
                                            </div>
                                        </TableCell>

                                        <TableCell>
                                            {/* Динамический Select для иконок */}
                                            <Select
                                                value={spinConfig.Rewards[index]?.icon}
                                                onValueChange={(value) => handleTableChange(value, index, 'icon')}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select icon"/>
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {/* Фильтруем иконки в зависимости от типа ассета */}
                                                    {spinConfig.Rewards[index]?.asset === 'coin' &&
                                                        icons
                                                            .filter(icon =>
                                                                // Фильтруем по ключевым словам 'coin', 'bag', 'chest' в названии
                                                                ['coin', 'bag', 'chest'].some(keyword => icon.title.includes(keyword))
                                                            )
                                                            .sort((a, b) => {
                                                                // Создаем массив ключевых слов для сортировки
                                                                const keywords = ['coin', 'bag', 'chest'];
                                                                const aKeyword = keywords.find(keyword => a.title.includes(keyword)) || '';
                                                                const bKeyword = keywords.find(keyword => b.title.includes(keyword)) || '';

                                                                // Сравниваем по ключевому слову
                                                                if (aKeyword !== bKeyword) {
                                                                    return keywords.indexOf(aKeyword) - keywords.indexOf(bKeyword);
                                                                }

                                                                // Если ключевые слова одинаковы, сортируем по цифре в названии
                                                                const aNumber = parseInt(a.title.replace(/\D/g, ''), 10) || 0; // Извлекаем число из строки
                                                                const bNumber = parseInt(b.title.replace(/\D/g, ''), 10) || 0; // Извлекаем число из строки
                                                                return aNumber - bNumber;
                                                            })
                                                            .map((icon: Icon) => (
                                                                <SelectItem key={icon.title}
                                                                            value={icon.url.replace('minio', 's3')}>
                                                                    <img
                                                                        src={icon.url.replace('minio', 's3')}
                                                                        width="50"
                                                                        height="50"
                                                                        alt={icon.title}
                                                                    />
                                                                </SelectItem>
                                                            ))}

                                                    {spinConfig.Rewards[index]?.asset === 'diamond' &&
                                                        icons
                                                            .filter(icon => icon.title.includes('diamond'))
                                                            .sort((a, b) => a.title.localeCompare(b.title))
                                                            .map((icon: Icon) => (
                                                                <SelectItem key={icon.title}
                                                                            value={icon.url.replace('minio', 's3')}>
                                                                    <img
                                                                        src={icon.url.replace('minio', 's3')}
                                                                        width="50"
                                                                        height="50"
                                                                        alt={icon.title}
                                                                    />
                                                                </SelectItem>
                                                            ))}

                                                    {spinConfig.Rewards[index]?.asset === 'ton' &&
                                                        icons
                                                            .filter(icon => icon.title.includes('ton'))
                                                            .sort((a, b) => a.title.localeCompare(b.title))
                                                            .map((icon: Icon) => (
                                                                <SelectItem key={icon.title}
                                                                            value={icon.url.replace('minio', 's3')}>
                                                                    <img
                                                                        src={icon.url.replace('minio', 's3')}
                                                                        width="50"
                                                                        height="50"
                                                                        alt={icon.title}
                                                                    />
                                                                </SelectItem>
                                                            ))}

                                                    {/* Если ассет не выбран, показываем все иконки */}
                                                    {!spinConfig.Rewards[index]?.asset &&
                                                        icons
                                                            .sort((a, b) => a.title.localeCompare(b.title))
                                                            .map((icon: Icon) => (
                                                                <SelectItem key={icon.title} value={icon.url}>
                                                                    <img
                                                                        src={icon.url.replace('minio', 's3')}
                                                                        width="50"
                                                                        height="50"
                                                                        alt={icon.title}
                                                                    />
                                                                </SelectItem>
                                                            ))}
                                                </SelectContent>
                                            </Select>
                                        </TableCell>
                                        <TableCell> {/* Минимальная ширина для Probabilities */}
                                            <Input
                                                className="w-full" // Полная ширина для Input
                                                type="number"
                                                value={prob}
                                                onChange={(e) => handleTableChange(e, index, 'Probabilities')}
                                                readOnly
                                            />
                                        </TableCell>
                                        <TableCell> {/* Минимальная ширина для ProbabilitiesLucky */}
                                            <Input
                                                className="w-full" // Полная ширина для Input
                                                type="number"
                                                value={spinConfig.ProbabilitiesLucky[index] ?? 0} // Установка значения по умолчанию
                                                onChange={(e) => handleTableChange(e, index, 'ProbabilitiesLucky')}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>

                        <CardFooter>
                            <Button type="button" onClick={() => setOpen(true)}
                                    className="mt-4 w-full">Submit</Button>
                        </CardFooter>
                    </Card>
                </div>


                {/* Диалоговое окно подтверждения удаления */}
                <AlertDialog open={open} onOpenChange={setOpen}>
                    <AlertDialogContent>
                        <AlertDialogHeader>
                            <AlertDialogTitle>Are u sure?</AlertDialogTitle>
                            <AlertDialogDescription>
                                This action cannot be undone. This will permanently changes fortune wheel configuration.
                            </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                            <AlertDialogCancel onClick={() => setOpen(false)}>Cancel</AlertDialogCancel>
                            <AlertDialogAction onClick={handleSubmit}>Confirm</AlertDialogAction>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialog>
            </form>
        </div>


    );
};

export default SpinConfigForm;
