import React, {useState} from 'react';

import {ResizableHandle, ResizablePanel, ResizablePanelGroup,} from "@/components/ui/resizable"
import {Card} from "@/components/ui/card";
import UsersMenu from "@/pages/users/users-menu";
import AllUsersPage from "@/pages/users/all-users/users";
import SearchRefByUserId from "@/pages/users/referrals/search-ref-by-user-id";
import {AddMoneyToAll} from "@/pages/users/add-money/add-money-to-all";
import SearchByUserName from "@/pages/users/search-user/search-user-by-username";

// Основной компонент
const UsersPage: React.FC = () => {
    // Состояние для отслеживания выбранной команды
    const [selectedCommand, setSelectedCommand] = useState("");

    // Функция для рендеринга выбранного компонента на главной странице
    const renderSelectedComponent = () => {
        switch (selectedCommand) {
            case "all-users":
                return <AllUsersPage />;
            case "referrals":
                return <SearchRefByUserId/>;
            case "send-money":
                return <AddMoneyToAll />;
            case "find-user":
                return <SearchByUserName />
            default:
                return <AllUsersPage />;
        }
    };

    return (
        <div className="w-full h-full">
            <Card className="h-full flex flex-col">
                <ResizablePanelGroup
                    direction="horizontal"
                    className="w-full h-full rounded-lg border md:min-w-[450px]"
                >
                    <ResizablePanel defaultSize={6} className="h-full">
                        <div className="flex w-full">
                            <UsersMenu onSelectCommand={setSelectedCommand}/>
                        </div>
                    </ResizablePanel>
                    <ResizableHandle disabled/>
                    <ResizablePanel defaultSize={50} className="h-full flex flex-col">
                        <ResizablePanelGroup direction="vertical" className="h-full flex-1">
                            {/* Контейнер для таблицы с явной высотой и скроллом */}
                            <ResizablePanel className="h-full p-6 overflow-auto flex-1">
                                {/* Здесь рендерится ваша таблица */}
                                <div className="h-full w-full overflow-auto">
                                    {renderSelectedComponent()}
                                </div>
                            </ResizablePanel>
                        </ResizablePanelGroup>
                    </ResizablePanel>
                </ResizablePanelGroup>
            </Card>
        </div>
    );
}

export default UsersPage;
