"use client";
import * as React from "react";
import {Button} from "@/components/ui/button";
import {Input} from "@/components/ui/input";
import {ArrowUpDown} from "lucide-react";
// Импортируйте необходимые хуки и функции
import {
    ColumnDef,
    ColumnFiltersState,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
    VisibilityState,
} from "@tanstack/react-table";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import {Table, TableBody, TableCell, TableHead, TableHeader, TableRow,} from "@/components/ui/table";

// Определите тип данных задачи
export type TaskInfo = {
    Active: boolean;
    Pinned: boolean;
    ID: number;
    Platform: string;
    Reward: number;
    Title: string;
    Type: string;
    ExpiresAt: string;
};

// Определите столбцы таблицы
export const columns: ColumnDef<TaskInfo>[] = [
    {
        accessorKey: "ID",
        header: ({column}) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    ID
                    <ArrowUpDown className="ml-2 h-4 w-4"/>
                </Button>
            );
        },
        filterFn: (row, columnId, filterValue) => {
            const id = row.getValue(columnId);
            return String(id).includes(String(filterValue));
        },
    },
    {
        accessorKey: "ExpiresAt",
        header: "ExpiresAt",
        cell: ({ getValue }) => (getValue() === "0001-01-01 00:00:00" ? "forever" : getValue())
    },
    {
        accessorKey: "Active",
        cell: ({getValue}) => (getValue() ? "Yes" : "No"),
        header: ({column}) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Active
                    <ArrowUpDown className="ml-2 h-4 w-4"/>
                </Button>
            );
        },
    },
    {
        accessorKey: "Pinned",
        cell: ({getValue}) => (getValue() ? "Yes" : "No"),
        header: ({column}) => {
            return (
                <Button
                    variant="ghost"
                    onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
                >
                    Pin
                    <ArrowUpDown className="ml-2 h-4 w-4"/>
                </Button>
            );
        },
    },
    {
        accessorKey: "Title",
        header: "Title",
    },
    {
        accessorKey: "Type",
        header: "Type",
    },
    {
        accessorKey: "Platform",
        header: "Platform",
    },
    {
        accessorKey: "Reward",
        header: "Reward",
    },
];

interface DataTableProps {
    columns: ColumnDef<TaskInfo>[];
    data: TaskInfo[]; // Указываем, что data — это массив TaskInfo
    onEdit: (id: number) => void;
    onCopy: (id: number) => void;
    onDelete: (id: number, type: string) => void;
}

export function TasksTable({
                               columns,
                               data,
                               onEdit,
                               onCopy,
                               onDelete,
                           }: DataTableProps) {
    const [sorting, setSorting] = React.useState<SortingState>([
        {id: 'ID', desc: true} // Sort by ID in descending order initially
    ]);
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});

    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onSortingChange: setSorting,
        getSortedRowModel: getSortedRowModel(),
        onColumnFiltersChange: setColumnFilters,
        getFilteredRowModel: getFilteredRowModel(),
        onColumnVisibilityChange: setColumnVisibility,
        state: {
            sorting,
            columnFilters,
            columnVisibility,
        },
    });

    return (
        <div className="h-auto">
            <div className="flex items-center py-2">
                <Input
                    placeholder="enter task id"
                    value={table.getColumn("ID")?.getFilterValue() as string}
                    onChange={(event) =>
                        table.getColumn("ID")?.setFilterValue(event.target.value)
                    }
                    className="max-w-sm"
                />
                <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                        <Button variant="outline" className="ml-auto">
                            Columns
                        </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                        {table
                            .getAllColumns()
                            .filter((column) => column.getCanHide())
                            .map((column) => {
                                return (
                                    <DropdownMenuCheckboxItem
                                        key={column.id}
                                        className="capitalize"
                                        checked={column.getIsVisible()}
                                        onCheckedChange={(value) =>
                                            column.toggleVisibility(!!value)
                                        }
                                    >
                                        {column.id}
                                    </DropdownMenuCheckboxItem>
                                );
                            })}
                    </DropdownMenuContent>
                </DropdownMenu>
            </div>
            <div className="rounded-md border">
                <Table className="text-left">
                    <TableHeader className="text-left">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <TableRow key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <TableHead key={header.id}>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(header.column.columnDef.header, header.getContext())}
                                    </TableHead>
                                ))}
                            </TableRow>
                        ))}
                    </TableHeader>
                    <TableBody>
                        {table.getRowModel().rows.length ? (
                            table.getRowModel().rows.map((row) => (
                                <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                                    {row.getVisibleCells().map((cell) => (
                                        <TableCell key={cell.id}>
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </TableCell>
                                    ))}
                                    <TableCell className="flex flex-row size-fit">
                                        <Button className="size-fit"
                                            variant="outline"
                                            onClick={() => onEdit(row.original.ID)}
                                        >
                                            Edit
                                        </Button>
                                        <Button className="size-fit"
                                            variant="outline"
                                            onClick={() => onCopy(row.original.ID)}
                                        >
                                            Copy
                                        </Button>
                                        <Button className="size-fit"
                                            variant="outline"
                                            onClick={() => onDelete(row.original.ID, row.original.Type)}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={columns.length} className="h-24 text-center">
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <div className="flex items-center justify-end space-x-2 py-4">
                <Button variant="outline" size="sm" onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}>
                    Previous
                </Button>
                <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
                    Next
                </Button>
            </div>
        </div>
    );
}
