// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cat-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    pointer-events: none;
    z-index: 1000;
}

.cat {
    width: 200px; /* Размер котика */
    height: auto; /* Автоматическая высота для сохранения пропорций */
    position: absolute; /* Позиционирование абсолютно */
    bottom: -100px; /* Начальное положение котика ниже экрана */
    animation: peek 10s ease-in-out; /* Установлено время анимации */
}

/* Анимация появления и исчезновения котика снизу */
@keyframes peek {
    0% {
        transform: translateY(0); /* Котик в исходном положении (под экраном) */
    }
    10% {
        transform: translateY(-10%); /* Котик выглядит на 30% от своего размера (выезжает на 70%) */
    }
    70% {
        transform: translateY(-20%); /* Котик остается на месте (выглядывает на 30%) */
    }
    90% {
        transform: translateY(0); /* Котик остается на месте (выглядывает на 30%) */
    }
    100% {
        transform: translateY(+20%); /* Котик плавно уходит обратно под экран */
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/fun/CatPeek.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,aAAa;IACb,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,YAAY,EAAE,kBAAkB;IAChC,YAAY,EAAE,mDAAmD;IACjE,kBAAkB,EAAE,+BAA+B;IACnD,cAAc,EAAE,2CAA2C;IAC3D,+BAA+B,EAAE,+BAA+B;AACpE;;AAEA,mDAAmD;AACnD;IACI;QACI,wBAAwB,EAAE,6CAA6C;IAC3E;IACA;QACI,2BAA2B,EAAE,8DAA8D;IAC/F;IACA;QACI,2BAA2B,EAAE,iDAAiD;IAClF;IACA;QACI,wBAAwB,EAAE,iDAAiD;IAC/E;IACA;QACI,2BAA2B,EAAE,0CAA0C;IAC3E;AACJ","sourcesContent":[".cat-container {\n    position: fixed;\n    width: 100%;\n    height: 100vh;\n    pointer-events: none;\n    z-index: 1000;\n}\n\n.cat {\n    width: 200px; /* Размер котика */\n    height: auto; /* Автоматическая высота для сохранения пропорций */\n    position: absolute; /* Позиционирование абсолютно */\n    bottom: -100px; /* Начальное положение котика ниже экрана */\n    animation: peek 10s ease-in-out; /* Установлено время анимации */\n}\n\n/* Анимация появления и исчезновения котика снизу */\n@keyframes peek {\n    0% {\n        transform: translateY(0); /* Котик в исходном положении (под экраном) */\n    }\n    10% {\n        transform: translateY(-10%); /* Котик выглядит на 30% от своего размера (выезжает на 70%) */\n    }\n    70% {\n        transform: translateY(-20%); /* Котик остается на месте (выглядывает на 30%) */\n    }\n    90% {\n        transform: translateY(0); /* Котик остается на месте (выглядывает на 30%) */\n    }\n    100% {\n        transform: translateY(+20%); /* Котик плавно уходит обратно под экран */\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
