// UsersPage.tsx
import React, {useEffect, useState} from 'react';
import PaginationControls from "@/pages/pagination/pagination";
import {toast} from "sonner";
import FetchWithAuth from "@/pages/login/fetch-with-auth";
import {columns, UsersSpinStatTable, UsersStat} from "@/pages/statistics/fortune/user-spin-stat/users-spin-stat-table";
import {useApi} from "@/pages/api-provider/api-context";
import {CardTitle} from "@/components/ui/card";


// Функция для получения данных
async function getData(apiUrl: string, currentPage: number, currentOffset: number): Promise<UsersStat> {
    try {
        const response = await FetchWithAuth(`${apiUrl}/admin/get-spin-stats?page=${currentPage}&offset=${currentOffset}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include',
        });
        if (!response.ok) {
            throw new Error(`Ошибка при запросе: ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error(error);
        toast.warning("Oops!", {
            description: `Something went wrong, checkout console! ${error}`,
        })
        throw error;
    }
}

// Основной компонент
const UsersSpinStatPage: React.FC = () => {
    const { apiUrl } = useApi();
    const [data, setData] = useState<UsersStat | null>(null); // Состояние для данных
    const [loading, setLoading] = useState<boolean>(true); // Состояние загрузки
    const [error, setError] = useState<string | null>(null); // Состояние ошибок
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(50);

    // useEffect для загрузки данных при монтировании компонента
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            setError(null);
            try {
                const data = await getData(apiUrl,page, offset); // Передаём apiUrl как параметр
                setData(data);
            } catch (err) {
                setError("Ошибка при загрузке данных");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [apiUrl, page, offset]); // Добавляем apiUrl в зависимости, чтобы перезагружать данные при его изменении

    // Обработчики для обновления page и offset
    const handlePageChange = async (newPage:number) => {
        setPage(newPage);
        try {
            const nextData = await getData(apiUrl,newPage, offset);
            setData(nextData);
        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
            setError("Ошибка при обновлении данных");
        }
    };

    const handleOffsetChange = async (newOffset: number) => {
        setOffset(newOffset);
        try {
            const nextData = await  getData(apiUrl,page, newOffset);
            setData(nextData);
        } catch (error) {
            console.error(error);
            toast.warning("Oops!", {
                description: `Something went wrong, checkout console! ${error}`,
            })
            setError("Ошибка при обновлении данных");
        }// Вызываем запрос с новым значением offset
    };

    const renderUsersSpinStatTable = () => {
        if (loading) {
            return <div>Загрузка...</div>;
        }
        if (error) {
            return <div>{error}</div>;
        }
        if (data) {
            return (
                <div>
                    <CardTitle>Users spin stats</CardTitle>
                    <UsersSpinStatTable columns={columns} data={data.spin_statistics ? data.spin_statistics : []} />
                    <PaginationControls
                        onPageChange={handlePageChange}
                        onOffsetChange={handleOffsetChange}
                        page={page}
                        offset={offset}
                    />
                </div>
            );
        }
        return <div>Нет данных для отображения</div>;
    };


    return renderUsersSpinStatTable();
}

export default UsersSpinStatPage;
